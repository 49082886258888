import React, { useState, useEffect } from "react";
import { formatedDatetime } from "../../utils/dataFormat";
import {
  CardTitle,
  CardHeader,
  CardFooter,
  Card,
  CardContent,
} from "../../@/components/card";
import {
  ShoppingCart,
  FileUp,
  ShoppingBasket,
} from "lucide-react";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";
import { calculateTotalCategories } from "../../utils/dataStats";
import { Button } from "../../@/components/button";
import { getFirebaseImageUrl } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config"; // Assuming you have the auth object exported from your Firebase configuration
import config from "../../config";

const RenderImageButton = ({ imageUrl }) => {
  const [workingUrl, setWorkingUrl] = useState(null);

  useEffect(() => {
    const fetchWorkingUrl = async () => {
      const url = await getFirebaseImageUrl(imageUrl);
      setWorkingUrl(url);
    };

    fetchWorkingUrl();
  }, [imageUrl]);

  return <Button onClick={() => window.open(workingUrl)}>View Receipt</Button>;
};

function SupermarketCard({ renderedReceiptId }) {
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [name, setName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user] = useAuthState(auth); // Check if the user is logged in

  useEffect(() => {
    const checkAuthStatus = () => {
      if (user) {
        // Check if the user is admin
        const adminEmails = config.adminEmails;
        // add null check for adminEmails
        if (adminEmails) {
          const isAdmin = adminEmails.includes(user.email);
          setIsAdmin(isAdmin);
        }
      }
    };

    checkAuthStatus();
  }, [user]);

  useEffect(() => {
    const fetchReceiptData = async () => {
      const data = await fetchReceiptDetails(
        renderedReceiptId,
        "allUserReceipts"
      );
      setReceiptDetails(data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  if (!receiptDetails) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="bg-white max-w-3xl w-full">
      {/* Centering the card on big screens with a maximum width of 400 */}
      <CardHeader className="font-bold">
        <CardTitle className="border-b-2 border-gray-200 pb-2">
          {receiptDetails?.processed_data?.store_name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        {/* Centering the content inside the card */}
        <div className="flex flex-col gap-2">
          <p className="text-gray-400">
            <ShoppingCart className="inline-block h-5 w-5 text-gray-400" />{" "}
            {formatedDatetime(
              receiptDetails?.processed_data?.datetime ?? "Unknown"
            )}
          </p>
          <p className="text-gray-400">
            <FileUp className="inline-block h-5 w-5 text-gray-400" />{" "}
            {formatedDatetime(receiptDetails?.request_timestamp ?? "Unknown")}
          </p>
          <p className="font-[500] text-gray-500">
            <ShoppingBasket className="inline-block h-5 w-5 text-gray-500" />{" "}
            {receiptDetails?.processed_data?.items.length} items
          </p>
          <p className="font-[500] text-gray-500">
            In{" "}
            {calculateTotalCategories(receiptDetails?.processed_data.items)}{" "}
            categoriesss
          </p>
          <p className="font-[500] text-gray-500">
            Total: {receiptDetails?.processed_data?.total_amount}
          </p>
        </div>
      </CardContent>
      <CardFooter>
        <RenderImageButton imageUrl={receiptDetails?.image_url} />
      </CardFooter>
    </Card>
  );
}

export default SupermarketCard;
