import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ChefHat,
  FileClock,
  Home,
  ShoppingCart,
  Settings,
  AudioLines,
} from "lucide-react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../@/components/tooltip";
import { useSelector } from "react-redux";

// Reusable Link Component
const SidebarLink = ({ to, icon: Icon, label, isActive }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={to}
          className={`flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8 ${isActive ? "bg-amber-200 text-accent-foreground" : "text-muted-foreground hover:bg-zinc-100"}`}
        >
          <Icon className="h-5 w-5" />
          <span className="sr-only">{label}</span>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{label}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default function DashboardSidebar() {
  const location = useLocation(); // Get the current location
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-white sm:flex">
        <nav className="flex flex-col items-center gap-4 px-2 sm:py-4">
          <Link
            to="/"
            className={`group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full transition-colors md:h-8 md:w-8 md:text-base ${location.pathname === "/" ? "bg-gray-100" : "bg-primary text-primary-foreground"}`}
          >
            <ChefHat className="h-4 w-4 transition-all group-hover:scale-110" />
            <span className="sr-only">FiveOut</span>
          </Link>
          <SidebarLink
            to="/dashboard"
            icon={Home}
            label="Dashboard"
            isActive={location.pathname === "/dashboard"}
          />
          <SidebarLink
            to="/dashboard/past-receipts"
            icon={FileClock}
            label="Past Receipts"
            isActive={location.pathname === "/dashboard/past-receipts"}
          />
          <SidebarLink
            to="/dashboard/grocery-list"
            icon={ShoppingCart}
            label="Grocery List"
            isActive={location.pathname === "/dashboard/grocery-list"}
          />
          {user?.betaTester && (
            <SidebarLink
              to="/dashboard/cook-with-me"
              icon={AudioLines}
              label="Cook With Me"
              isActive={location.pathname === "/dashboard/cook-with-me"}
            />
          )}
        </nav>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-4">
          <SidebarLink
            to="#"
            icon={Settings}
            label="Settings"
            isActive={location.pathname === "#"}
          />
        </nav>
      </aside>
    </div>
  );
}
