import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/table";

import { CardTitle, CardDescription, CardHeader, CardFooter, Card, CardContent } from "../../@/components/card"

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config";
import NorthIcon from "@mui/icons-material/North";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";


const InvoiceTable = ({renderedReceiptId, collectionName, skeletonData }) => {
  const [user] = useAuthState(auth);
  const [recipieData, setRecipieData] = useState(null);

  useEffect(() => {
    // print value of renderedReceiptId
    
    
    const fetchReceiptData = async () => {
      const data = await fetchReceiptDetails(renderedReceiptId, collectionName);
      setRecipieData(data.processed_data);

    };

    fetchReceiptData();
  }, [renderedReceiptId]);





  const [items, setItems] = useState(() =>
    recipieData?.items?.sort((a, b) => b.total_price - a.total_price)
  );

  useEffect(() => {
    setItems(() => recipieData?.items?.sort((a, b) => b.total_price - a.total_price));
  }, [recipieData]);

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortType, setSortType] = useState("total_price");

  const sortItemsByType = (type) => {
    const sortedItems = [...items]?.sort((a, b) => {
      if (sortOrder === "asc") {
        return (a[type] || 0) - (b[type] || 0);
      } else {
        return (b[type] || 0) - (a[type] || 0);
      }
    });
    setItems(sortedItems);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortType(type);
  };

  return (
    <Card className="transition-all duration-300 hover:shadow-lg hover:-translate-y-1 bg-white max-w-3xl w-full">
      <CardHeader>
        <CardTitle className="text-xl md:text-2xl text-center">Receipt Breakdown</CardTitle>
      </CardHeader>
      <CardContent>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <div onClick={() => sortItemsByType("item_name")} className="cursor-pointer">
                Item{" "}
                <NorthIcon
                  fontSize="small"
                  style={{
                    transform:
                      sortType === "item_name" && sortOrder === "asc" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              </div>
            </TableHead>
            <TableHead className="text-left cursor-pointer">
              <div onClick={() => sortItemsByType("category")}>
                Category{" "}
                <NorthIcon
                  fontSize="small"
                  style={{
                    transform:
                      sortType === "category" && sortOrder === "asc" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              </div>
            </TableHead>
            <TableHead className="text-right cursor-pointer">
              <div onClick={() => sortItemsByType("total_price")}>
                Amount Spent{" "}
                <NorthIcon
                  fontSize="small"
                  style={{
                    transform:
                      sortType === "total_price" && sortOrder === "asc" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {user
            ? items?.map((invoice, idx) => (
                <TableRow key={idx}>
                  <TableCell>{invoice?.item_name}</TableCell>
                  <TableCell className="text-left">{invoice?.category?.length > 1 ? "•" : ( invoice?.category?.[0] || "N/A" )}</TableCell>
                  <TableCell className="text-right">{invoice?.total_price || "N/A"}</TableCell>
                </TableRow>
              ))
            : items?.slice(0, 4).map((invoice, idx) => (
                <TableRow key={idx}>
                  <TableCell>{invoice?.item_name}</TableCell>
                  <TableCell className="text-left">{invoice?.category?.length > 1 ? "•" : ( invoice?.category?.[0] || "N/A" )}</TableCell>
                  <TableCell className="text-right">{invoice?.total_total_price || "N/A"}</TableCell>
                </TableRow>
              ))}
          {skeletonData?.map((invoice, idx) => (
            <TableRow key={idx}>
              <TableCell>{invoice?.item_name}</TableCell>
              <TableCell className="text-left">{invoice?.category?.length > 1 ? "•" : ( invoice?.category?.[0] || "N/A" )}</TableCell>
              <TableCell className="text-right">{invoice?.total_price || "N/A"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </CardContent>
    </Card>
  );
};
export default InvoiceTable;