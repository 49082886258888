import React, { useEffect, useState, useMemo } from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import { TrendingUp } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../@/components/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../@/components/chart";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";

export function DonutChart({ renderedReceiptId }) {
  const [chartData, setChartData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    const fetchReceiptData = async () => {
      const fetchedData = await fetchReceiptDetails(renderedReceiptId, "allUserReceipts");
      processData(fetchedData.processed_data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  const processData = (data) => {
    if (data && data.items) {
      const categoryTotals = {};
      
      data.items.forEach((item) => {
        const category = Array.isArray(item.category) ? item.category[0] : item.category;
        const price = parseFloat(item.total_price);
        
        categoryTotals[category] = (categoryTotals[category] || 0) + price;
      });

      const newChartData = Object.entries(categoryTotals).map(([category, value]) => ({
        name: category,
        value,
      }));

      setChartData(newChartData);
      setTotalValue(newChartData.reduce((acc, curr) => acc + curr.value, 0));
    }
  };

  const COLORS = ['hsl(var(--chart-1))', 'hsl(var(--chart-2))', 'hsl(var(--chart-3))', 'hsl(var(--chart-4))', 'hsl(var(--chart-5))'];

  const chartConfig = useMemo(() => {
    return chartData.reduce((config, category, index) => {
      config[category.name] = {
        label: category.name,
        color: COLORS[index % COLORS.length],
      };
      return config;
    }, {
      value: { label: "Amount" }
    });
  }, [chartData]);

  if (!chartData.length) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>Your expenses per category</CardTitle>
        <CardDescription>Breakdown of expenses by category</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent />}
            />
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              innerRadius={60}
              outerRadius={80}
              strokeWidth={5}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          €{totalValue.toFixed(2)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Spent
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none">
          {/* You might want to calculate this percentage based on the data */}
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing expenses breakdown by category
        </div>
      </CardFooter>
    </Card>
  );
}
