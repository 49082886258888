import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../@/components/select";
import { filterReceiptsByDate } from "../../utils/firestoreUtils";
import { ShoppingBasket, FileUp } from "lucide-react";

export const getFilteredReceipts = (filter = "uploadTime", selectedDate, user) => {
  return filterReceiptsByDate(selectedDate, filter, user).catch((error) => {
    console.error("Error filtering receipts:", error);
    return [];
  });
};

export const FilterSelect = ({ currentFilter, setCurrentFilter }) => (
  <div className="my-4 bg-white max-w-sm mx-auto rounded-xl">
    <Select onValueChange={setCurrentFilter} value={currentFilter}>
      <SelectTrigger className="w-full">
        <SelectValue>
          {currentFilter === "ShoppingTime" ? (
            <>
              <ShoppingBasket className="inline-block h-5 w-5 mr-2" />
              Shopping Date
            </>
          ) : (
            <>
              <FileUp className="inline-block h-5 w-5 mr-2" />
              Upload Date
            </>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="bg-white">
        <SelectItem value="ShoppingTime" className="hover:bg-gray-200 cursor-pointer">
          <ShoppingBasket className="inline-block h-5 w-5 mr-2" />
          Shopping Date
        </SelectItem>
        <SelectItem value="uploadTime" className="hover:bg-gray-200 cursor-pointer">
          <FileUp className="inline-block h-5 w-5 mr-2" />
          Upload Date
        </SelectItem>
      </SelectContent>
    </Select>
  </div>
);