import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to="/" className="flex items-center">
              <svg width="28" height="28" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="150"
                  cy="150"
                  r="140"
                  stroke="black"
                  stroke-width="10"
                  fill="white"
                />

                <path
                  d="M160 100V200M140 100V200M180 130H120M180 170H120"
                  stroke="black"
                  stroke-width="15"
                  stroke-linecap="round"
                />

                <path
                  d="M150 60C150 60 120 90 120 120C120 150 150 150 150 150C150 150 180 150 180 120C180 90 150 60 150 60Z"
                  fill="black"
                />
                <path
                  d="M150 150V220"
                  stroke="black"
                  stroke-width="10"
                  stroke-linecap="round"
                />
              </svg>
              <span className="ml-2 text-xl font-bold">FiveOut</span>
            </Link>
            <p className="text-gray-500 text-base">
              Track your groceries, save money, and get healthy.
            </p>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Resources</h3>
                <ul className="mt-4 space-y-4">
                  <li><Link to="/guide" className="text-base text-gray-500 hover:text-gray-900">Guide</Link></li>
                  <li><Link to="/affiliate" className="text-base text-gray-500 hover:text-gray-900">Affiliate</Link></li>
                  <li><Link to="/#faqs" className="text-base text-gray-500 hover:text-gray-900">FAQs</Link></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li><Link to="/roadmap" className="text-base text-gray-500 hover:text-gray-900">Roadmap</Link></li>
                  <li><Link to="/jobs" className="text-base text-gray-500 hover:text-gray-900">Careers</Link></li>
                  <li><a href="mailto:support@fiveout.com" className="text-base text-gray-500 hover:text-gray-900">Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li><Link to="/terms-and-conditions" className="text-base text-gray-500 hover:text-gray-900">Terms</Link></li>
                  <li><Link to="/privacy-policy" className="text-base text-gray-500 hover:text-gray-900">Privacy</Link></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Social</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="https://twitter.com/fiveoutapp" target="_blank" rel="noopener noreferrer" className="text-base text-gray-500 hover:text-gray-900">Twitter</a></li>
                  <li><a href="https://www.instagram.com/fiveoutapp/" target="_blank" rel="noopener noreferrer" className="text-base text-gray-500 hover:text-gray-900">Instagram</a></li>
                  <li><a href="https://www.linkedin.com/company/fiveout/" target="_blank" rel="noopener noreferrer" className="text-base text-gray-500 hover:text-gray-900">LinkedIn</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}