import React from 'react';
import { Dialog, DialogContent, DialogFooter } from "../../@/components/dialog";
import { Alert, AlertTitle, AlertDescription } from "../../@/components/alert";
import { Button } from "../../@/components/button";
import { AlertTriangle } from "lucide-react";

export default function DeleteReceiptDialog({ isOpen, onClose, onDelete }) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Are you sure you want to delete this receipt?</AlertTitle>
          <AlertDescription>
            This action cannot be undone. This will permanently delete the receipt
            and remove the data from our servers.
          </AlertDescription>
        </Alert>
        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={onDelete}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
