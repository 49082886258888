import apiClient from '../apiClient';
import endpoints from '../endpoints';

export const uploadAndProcessImage = async (key, image) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    const response = await apiClient.post(endpoints.uploadAndProcessImage, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { key },
    });
    console.log(`Upload and Process Image API call successful`);
    return response;
  } catch (error) {
    console.error(`Error in Upload and Process Image API call:`, error);
    throw error;
  }
};