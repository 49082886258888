import React, { useState } from "react";
import { Button } from "../../../@/components/button";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="faq-section" className="w-full pr-3 border-b border-border2 text-left">
      <div className="w-full flex flex-col justify-between items-start py-4 sm:py-5 focus:outline-none text-base sm:text-lg font-medium">
        <div
          className="flex justify-between items-center w-full text-left cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-sm sm:text-base md:text-lg w-full pr-4">{question}</span>
          <div className="flex-shrink-0">
            {isOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-minus">
                <path d="M5 12h14"></path>
              </svg>
            ) : (
              <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3.75V14.25" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.75 9H14.25" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            )}
          </div>
        </div>
        {isOpen && (
          <div className="text-sm sm:text-base text-left font-medium text-gray-500 pt-3 sm:pt-6 max-w-3xl">
            {answer}
          </div>
        )}
      </div>
    </div>
  );
};

const FAQs = () => {
  const faqData = [
    {
      question: "What happens to my receipts after I have uploaded them?",
      answer: (
        <div>
          <p>For unregistered users, the program will keep your receipts for 15 days and afterwards they will be deleted.</p>
          <p>For registered users, we keep receipts for 30 days after the user's last activity. If inactive for 30 days, all data is deleted.</p>
          <p className="mt-2">
            Nevertheless, if you want to delete all your receipts right now, please send an email to aman[at]5outapp.com. We will take care of it as first priority.
          </p>
        </div>
      ),
    },
    {
      question: "How does it work?",
      answer: "It is converting image to text using OCR and then structuring the text using LLM.",
    },
    {
      question: "How accurate is it?",
      answer: "Currently the accuracy is medium. It will be getting better.",
    },
    {
      question: "Why do I need this?",
      answer: (
        <div>
          <p>There is a notion that healthier food is usually expensive.</p>
          <Button variant="link" href="https://news.ycombinator.com/item?id=34396605"><i>We are not sure if it is true</i></Button>
          <p>We can do better, but first, we have to start tracking it. With better tracking, you can optimize our spending and be sure that we are spending on healthier food options.</p>
        </div>
      ),
    },
  ];

  return (
    <section className="pt-8 sm:pt-16 w-full max-w-[90%] sm:max-w-4xl mx-auto flex flex-col items-center text-center pb-16 sm:pb-24 box-content" id="faq-section">
      <div className="mb-6 sm:mb-10 max-w-3xl">
        <div className="max-w-3xl">
          <h3 className="text-2xl sm:text-[2rem] md:text-[3rem] leading-[1.15] mb-4 !tracking-[-0.1rem]">
            <span style={{display: "inline-block", verticalAlign: "top", textDecoration: "inherit", textWrap: "balance"}}>
              <span style={{position: "relative", zIndex: 2}}>Frequently asked questions</span>
            </span>
          </h3>
        </div>
      </div>
      <div className="w-full sm:w-[56rem] -mt-2 sm:mt-4 -mb-6 sm:-mb-10">
        <div className="w-full">
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQs;