import { AuthShadcnNew } from "../components/auth/AuthShadcnNew"

const Login = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow overflow-auto flex items-center justify-center">
        <AuthShadcnNew />
      </div>
    </div>
  );
};

export default Login;

