import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../@/components/dialog.js";
import { Input } from "../../../@/components/input.js";
import { Button } from "../../../@/components/button.js";
import { Label } from "../../../@/components/label.js";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../@/components/select.js";
import { backgroundColorsForNewList } from "./groceryData.js";
const NewListDialog = ({
  open,
  onOpenChange,
  newListName,
  setNewListName,
  newListColor,
  setNewListColor,
  createNewList,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Grocery List</DialogTitle>
          <DialogDescription>
            Enter a name and choose a color for your new grocery list.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <Label htmlFor="list-name">List Name</Label>
            <Input
              id="list-name"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="eg. Home Essentials, Party Needs, etc."
            />
          </div>
          <div>
            <Label htmlFor="list-color">Background Color</Label>
            <Select
              onValueChange={(value) => setNewListColor(value)}
              defaultValue={newListColor}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-white">
                {backgroundColorsForNewList.map((color) => (
                  <SelectItem key={color.value} value={color.value}>
                    {color.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={createNewList}>Create List</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NewListDialog;
