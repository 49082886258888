import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config.js";
import { extractText } from "../api/services/textService";
import { callLlmStructured } from "../api/services/llmService";
import { copyReceiptDataToUserCollection } from "../utils/firestoreUtils";
import ReceiptSummary from "../components/track-foods/ReceiptSummary";
import InvoiceTable from "../components/track-foods/InvoiceTable.js";
import { AuthShadcnNew } from "../components/auth/AuthShadcnNew.js";
import Dislike from "../components/dislikeFeedback/Dislike.js";
import { SkeletonReceiptSummary, SkeletonInvoiceTable } from "../components/common/SkeletonLoading.js";

export function ResultTrackFoods() {
  const [loading, setLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user] = useAuthState(auth);
  const [receiptKey, setReceiptKey] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedKey = localStorage.getItem('lastUploadedReceiptKey');
    
    if (!storedKey) {
      setErrorOccurred(true);
      setErrorMessage("No receipt key found. Please upload a receipt first.");
      setLoading(false);
      return;
    }

    setReceiptKey(storedKey);

    const processReceipt = async () => {
      try {
        // Step 1: Extract text
        const extractResult = await extractText(storedKey);
        if (!extractResult || Object.keys(extractResult).length === 0) {
          setErrorOccurred(true);
          setErrorMessage("Failed to extract text from the receipt image.");
          return;
        }

        // Step 2: Call LLM structured
        const llmResult = await callLlmStructured(storedKey);
        if (!llmResult || Object.keys(llmResult).length === 0) {
          setErrorOccurred(true);
          setErrorMessage("Failed to process the receipt data with LLM.");
          return;
        }

        // Step 3: Copy receipt data to user collection (if user exists)
        if (user) {
          await copyReceiptDataToUserCollection(storedKey);
        }

        // Process completed successfully
      } catch (error) {
        console.error("Error processing receipt:", error);
        setErrorOccurred(true);
        setErrorMessage(error.message || "An unexpected error occurred while processing the receipt.");
      } finally {
        // Set loading to false after all processing (success or error)
        setLoading(false);
      }
    };

    processReceipt();
  }, [user]);

  if (loading) {
    return (
      <>
        <SkeletonReceiptSummary />
        <SkeletonInvoiceTable />
      </>
    );
  }

  if (errorOccurred) {
    return (
      <p className="text-red-500 m-4 text-center">
        {errorMessage}
      </p>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow overflow-auto">
        <div className="flex flex-col items-center gap-6 p-4">
          {receiptKey && (
            <>
              <ReceiptSummary receiptKey={receiptKey} />
              <InvoiceTable renderedReceiptId={receiptKey} collectionName="groceries_temp" />
            </>
          )}
          {user && receiptKey && <Dislike param={receiptKey} />}
          {!user && <AuthShadcnNew />}
        </div>
      </div>
    </div>
  );
}