import React, { useEffect, useState } from "react";

import { History, SlidersHorizontal } from "lucide-react"; // Adjust the import path
import { Toggle } from "./toggle/Toggle";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../@/components/sheet";
import { fetchLastConversations } from "../../utils/firestoreUtils";
const ToggleMenu = ({
  changeTurnEndType,
  activeView,
  setActiveView,
  isConnected,
  setSelectedConversation,
  connectConversation,
  disconnectConversation,
}) => {
  const [isToggleVisible, setToggleVisible] = useState(false);
  const [pastConversations, setPastConversations] = useState([]);

  useEffect(() => {
    fetchLastConversations().then((conversations) => {
      console.log("conversations", conversations);
      setPastConversations(conversations);
    });
  }, []);

  const handleToggleClick = () => {
    setToggleVisible(!isToggleVisible);
  };

  const handleSelectConversation = async (conversation) => {
    if (isConnected) {
      await disconnectConversation();
    }
    setSelectedConversation(conversation);

    connectConversation(conversation);

    setActiveView("conversation");
  };
  return (
    <div className="relative">
      <div className="flex items-center gap-4">
        <Sheet>
          <SheetTrigger asChild>
            <History className="cursor-pointer text-gray-500 hover:text-black" />
          </SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>Past Conversations </SheetTitle>
              <SheetDescription>
                {pastConversations?.length > 0
                  ? "View your past 5 conversations here."
                  : "No past conversations found."}
              </SheetDescription>
            </SheetHeader>
            <div className="grid justify-start gap-4 py-4">
              {pastConversations?.map((conversation) => (
                <SheetClose
                  className="text-left bg-gray-200 px-4 py-2 rounded-xl"
                  key={conversation.id}
                  onClick={() => handleSelectConversation(conversation)}
                >
                  {conversation.title
                    ? conversation.title
                    : `Conversation on ${conversation.timestamp?.slice(0, 10)}`}
                </SheetClose>
              ))}
            </div>
          </SheetContent>
        </Sheet>{" "}
        {isConnected && (
          <SlidersHorizontal
            onClick={handleToggleClick}
            className="cursor-pointer text-gray-500 hover:text-black"
          />
        )}
      </div>
      {isToggleVisible && (
        <div className="absolute top-7 right-0 z-[9999] bg-white shadow-lg rounded-3xl p-4">
          <Toggle
            defaultValue={false}
            labels={["push talk", "auto"]}
            values={["none", "server_vad"]}
            onChange={(_, value) => changeTurnEndType(value)}
            className=""
          />
          <div className="flex justify-center text-base mt-4 font-[500] rounded-full bg-gray-200 ">
            <button
              className={`px-4 py-2 text-sm font-medium ${
                activeView === "conversation"
                  ? "text-white bg-black rounded-full"
                  : "text-black"
              } `}
              onClick={() => setActiveView("conversation")}
            >
              conversation
            </button>
            <button
              className={`px-4 py-2 text-sm font-medium ${
                activeView === "listening"
                  ? "text-white bg-black rounded-full"
                  : "text-black"
              }  `}
              onClick={() => setActiveView("listening")}
            >
              listening
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToggleMenu;
