import React from "react";
import { Mic, X, Zap } from "lucide-react";

interface ConversationControlsProps {
  items: any[];
  isConnected: boolean;
  canPushToTalk: boolean;
  isRecording: boolean;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  connectConversation: () => Promise<void>;
  disconnectConversation: () => Promise<void>;
}

const ConversationControls: React.FC<ConversationControlsProps> = ({
  items,
  isConnected,
  canPushToTalk,
  isRecording,
  startRecording,
  stopRecording,
  connectConversation,
  disconnectConversation,
}) => {
  return (
    <div className="fixed bottom-[45px] sm:bottom-[30px] left-0 right-0 text-center">
      <div className="flex justify-center items-center gap-6">
        {isConnected && canPushToTalk && (
          <button
            className={`text-white p-4 rounded-full ${
              isRecording ? "bg-red-500 animate-pulse" : "bg-green-500"
            }`}
            disabled={!isConnected || !canPushToTalk}
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={startRecording}
            onTouchEnd={stopRecording}
          >
            <Mic size={34} />
          </button>
        )}
        <button
          className={`p-2 border-2 flex items-center justify-center gap-2 group rounded-full ${
            isConnected
              ? "border-red-500 bg-red-100 text-sm text-red-500 hover:bg-red-200"
              : "border-green-500 text-green-500 bg-green-100 font-[500] text-lg"
          }`}
          onClick={isConnected ? disconnectConversation : connectConversation}
        >
          {isConnected ? (
            <>
              <X
                size={20}
                className="bg-red-200 rounded-full p-0.5 group-hover:bg-white"
              />
            </>
          ) : (
            <>
              <Zap fill="lightgreen" size={34} />
            </>
          )}
        </button>
      </div>
      {canPushToTalk && items.length > 0 && (
        <p className="mt-4 text-gray-500">Hold the Mic to talk</p>
      )}
      {!canPushToTalk && items.length > 0 && (
        <p className="mt-4 text-gray-500">In Auto Mode you can talk normally</p>
      )}
    </div>
  );
};

export default ConversationControls;
