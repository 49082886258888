export const generateConversationTitle = async (conversationData) => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "user",
          content: `Generate a accurate title of 4 words or less from this conversation related to cooking: ${conversationData}`,
        },
      ],
    }),
  });

  const data = await response.json();

  return data.choices[0].message.content.trim();
};
