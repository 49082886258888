import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
} from "../../../@/components/card";
import ExpenseChart from "../../charts/ExpenseChart";
import GroceryCategoryChart from "../../charts/GroceryCategoryChart";
import { auth } from "../../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { fetchUserReceipts } from "../../../utils/firestoreUtils";
import { calculatePercentageIncrease } from "../../../utils/dataStats";
import AddReceiptCard from "./AddReceiptCard";
import TryFiveOutChat from "./TryFiveOutChat";
import { Link } from "react-router-dom";

function Dashboard() {
  const [user] = useAuthState(auth);
  const [dashboardData, setDashboardData] = useState({
    totalReceiptsCount: 0,
    totalAmountSpent: 0,
    percentageIncrease: 0,
    monthlyExpenseAggregation: {},
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (user) {
        const receipts = await fetchUserReceipts(user.uid);
        const data = processDashboardData(receipts);
        setDashboardData(data);
      }
    };

    fetchDashboardData();
  }, [user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  
  const processDashboardData = (receipts) => {
    let totalReceiptsCount = receipts?.length || 0;
    let totalAmountSpent = 0;
    let monthlyExpenseAggregation = {};

    receipts.forEach((receipt) => {
      const amount = receipt?.processed_data?.total_amount || 0;

      totalAmountSpent += amount;


      const date = receipt?.processed_data?.datetime ?? "Unknown";
      const month = date.slice(0, 7);
      monthlyExpenseAggregation[month] =

        (monthlyExpenseAggregation[month] || 0) + amount;
    });

    const percentageIncrease = calculatePercentageIncrease(
      monthlyExpenseAggregation
    );

    return {
      totalReceiptsCount,
      totalAmountSpent,
      percentageIncrease,
      monthlyExpenseAggregation,
    };
  };

  return (
    <div>
      <div className="grid flex-1 items-start  gap-4  md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
        <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
          <div className="grid gap-4 sm:grid-cols-2">
            <AddReceiptCard />

            <Card className=" w-full">
              <CardHeader className="pb-2">
                <CardDescription>Total</CardDescription>
                <CardTitle className="text-4xl">
                  {dashboardData.totalReceiptsCount}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-xs text-muted-foreground">Receipts</div>
              </CardContent>
            </Card>

            {/* Total Expense Card */}
            <Card className=" w-full">
              <CardHeader className="pb-2">
                <CardDescription>Total Expense</CardDescription>
                <CardTitle className="text-4xl">
                  €{dashboardData.totalAmountSpent.toFixed(1)}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-xs text-muted-foreground">
                  {dashboardData.percentageIncrease.toFixed(1)}% from last month
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div>
          <TryFiveOutChat />
        </div>
      </div>{" "}
      {/* Expense Chart */}
      <div className="my-4">
        <ExpenseChart data={dashboardData.monthlyExpenseAggregation} />
      </div>
      {/* Grocery Category Chart */}
      <div className="my-4">
        <GroceryCategoryChart />
      </div>
      <VisitPastReceipts />
    </div>
  );
}

export default Dashboard;

function VisitPastReceipts() {
  return (
    <div>
      <section className="py-4 ">
        <div className=" ">
          <div className="relative w-full mx-auto px-4 md:px-12 py-12  rounded-4xl bg-gradient-to-r from-amber-600 to-orange-600 rounded-xl overflow-hidden">
            <img
              className="absolute top-0 left-1/2 transform -translate-x-1/2 h-full object-cover"
              src="https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/cta/circle-bg.svg"
              alt=""
            />
            <div className="relative text-center">
              <span className="block text-2xl  md:text-3xl font-semibold text-zinc-300 mb-6">
                Wants to analyze your previous grocery bills?
              </span>
              <h2 className="font-heading text-4xl sm:text-5xl  font-bold text-white mb-8">
                <span>See your past </span>
                <span className="font-serif italic">receipts!</span>
              </h2>
              <Link
                className="relative group inline-block w-full sm:w-auto py-4 px-6 text-orange-900 hover:text-white font-semibold bg-white rounded-md overflow-hidden transition duration-300"
                to="/dashboard/past-receipts"
              >
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-110 transition duration-500"></div>
                <div className="relative flex items-center justify-center">
                  <span className="mr-4">See Receipts</span>
                  <span>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
