const ListeningIndicator = ({ items }) => {
  return (
    <div className="min-h-[60vh] h-full grid place-items-center relative">
      <div className="space-y-2 mb-auto absolute top-0 -z-0 mx-auto w-full">
        <div className="flex items-center justify-center space-x-1  ">
          {[...Array(15)].map((_, i) => (
            <div
              key={i}
              className="w-1  bg-teal-500 rounded-full animate-pulse"
              style={{
                animationDelay: `${i * 0.1}s`,
                height: `${i * Math.random() * 5}px`,
              }}
            ></div>
          ))}
        </div>
      </div>
      {!items.length && (
        <div className="text-black relative z-10 text-3xl font-bold">
          <p className="">Start cooking with FiveOut-AI...</p>
        </div>
      )}
      {items.length > 0 && items[items.length - 1].role === "assistant" && (
        <div className="text-black relative text-center text-3xl font-bold">
          <p className="">
            {items[items.length - 1].formatted.transcript ||
              items[items.length - 1].formatted.text ||
              "(truncated)"}
          </p>
        </div>
      )}
    </div>
  );
};

export default ListeningIndicator;
