import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Search, Plus } from "lucide-react";
import { Input } from "../../../@/components/input.js";
import { Button } from "../../../@/components/button.js";

import SelectedListViewer from "./SelectedListViewer.jsx";
import NewListDialog from "./NewListDialog.jsx";
import GroceryListDisplay from "./GroceryListDisplay.jsx";
import { toast, Toaster } from "react-hot-toast";
import { doc, getDoc, } from "firebase/firestore";
import { db, auth } from "../../../firebase/config.js";
import {
  createNewShoppingList,
  deleteShoppingList,
} from "../../../firebase/grocerylistUtils.js";
import { backgroundColors } from "./groceryData.js";

export default function GroceryListViewer() {
  const [user] = useAuthState(auth);
  const [lists, setLists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedList, setSelectedList] = useState(null);
  const [isNewListDialogOpen, setIsNewListDialogOpen] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [newListColor, setNewListColor] = useState(backgroundColors[0].value);
  const [isLoading, setIsLoading] = useState(true);

  const filteredLists = lists.filter(
    (list) =>
      list.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      list.items.some(
        (item) =>
          item.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.category.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const createNewList = async () => {
    if (newListName.trim() !== "") {
      setIsLoading(true);
      const newList = {
        name: newListName,
        items: [],
        backgroundColor: newListColor,
        date_created: new Date(),
      };
      const newListId = await createNewShoppingList(user.uid, newList);
      setLists([...lists, { ...newList, id: newListId }]);
      setNewListName("");
      setNewListColor(backgroundColors[0].value);
      setIsNewListDialogOpen(false);
    }
    toast.success("List created successfully");
    setIsLoading(false);
  };

  const deleteList = async (id) => {
    setIsLoading(true);
    await deleteShoppingList(user.uid, id);
    setLists(lists.filter((list) => list.id !== id));
    toast.success("List deleted successfully");
    setIsLoading(false);
  };

  const updateListInParent = (updatedList) => {
    setLists((prevLists) => {
      const newLists = prevLists.map((list) => (list.id === updatedList.id ? updatedList : list));
      console.log("Updated Lists:", newLists); // Debugging: Check updated lists
      return newLists;
    });
  };

  useEffect(() => {
    if (user) {
      const fetchUserLists = async () => {
        const userRef = doc(db, `users/${user.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userLists = userDoc.data().shopping_lists || [];
          const listPromises = userLists.map(async (listId) => {
            const listRef = doc(db, `shopping_lists/${listId}`);
            const listDoc = await getDoc(listRef);
            if (listDoc.exists()) {
              const listData = listDoc.data();
              const itemPromises = listData.items.map(async (itemId) => {
                const itemRef = doc(
                  db,
                  `shopping_lists/${listId}/items/${itemId}`
                );
                const itemDoc = await getDoc(itemRef);
                return itemDoc.exists() && !itemDoc.data().date_removed
                  ? { item_id: itemDoc.id, ...itemDoc.data() }
                  : null;
              });
              const items = await Promise.all(itemPromises);
              return {
                id: listDoc.id,
                ...listData,
                items: items.filter((item) => item !== null),
              };
            }
            return null;
          });
          const listsData = await Promise.all(listPromises);
          setLists(listsData.filter((list) => list !== null));
        }
        setIsLoading(false);
      };
      fetchUserLists();
    }
  }, [user]);


  return (
    <div className="min-h-screen flex flex-col items-center  px-0 sm:px-6 lg:px-8">
      <Toaster />
      {isLoading && (
        <div className="h-screen w-full fixed top-0 left-0  bg-neutral-900/40 z-[9998] grid place-items-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/parts-1ffae.appspot.com/o/icons%2Floading.png?alt=media&token=6773e6d3-199b-4ae1-babf-d2ce6c552816"
            alt="logo"
            className="animate-spin "
            width={100}
            height={100}
          />
        </div>
      )}

      {!selectedList && (
        <div className="w-full max-w-4xl mt-4 space-y-8">
          <h1 className="text-3xl font-bold text-center  text-gray-900">
            Grocery Lists Viewer
          </h1>
          {/*--------------- search bar ---------------*/}
          <div className="">
            <div className="relative flex-grow bg-white mr-0">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="text"
                placeholder="Search lists, items or categories..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <Button
              className="bg-gradient-to-r from-green-600 to-lime-600 text-white mt-4 rounded-full ml-auto flex text-base scale-90"
              onClick={() => setIsNewListDialogOpen(true)}
            >
              <Plus className="mr-2 h-4 w-4 " /> New List
            </Button>
          </div>

          {/*--------------- list of grocery lists ---------------*/}
          <GroceryListDisplay
            filteredLists={filteredLists}
            setSelectedList={setSelectedList}
            deleteList={deleteList}
          />

          {/*--------------- new list dialog ---------------*/}
          <NewListDialog
            open={isNewListDialogOpen}
            onOpenChange={setIsNewListDialogOpen}
            newListName={newListName}
            setNewListName={setNewListName}
            newListColor={newListColor}
            setNewListColor={setNewListColor}
            createNewList={createNewList}
          />
        </div>
      )}

      {selectedList && (
        <SelectedListViewer
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          setIsLoading={setIsLoading}
          updateListInParent={updateListInParent} // Pass the update function
        />
      )}
      {lists.length === 0 && (
        <div className="text-center text-gray-500">
          <p>No lists found. Create a new list to get started.</p>
        </div>
      )}
    </div>
  );
}