import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Analytics } from "@vercel/analytics/react";
import "./globals.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import { routes } from "./routes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/config";
import { setUser } from './redux/userSlice';
import { fetchUserDetails } from './utils/firestoreUtils';

const router = createBrowserRouter(routes);

const App = () => {
  const [firebaseUser] = useAuthState(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const updateUserState = async () => {
      if (firebaseUser) {
        const userDetails = await fetchUserDetails(firebaseUser.uid);
        if (userDetails) {
          dispatch(setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            photoURL: firebaseUser.photoURL,
            ...userDetails, // Add additional user details from Firestore
          }));
        }
      }
    };

    updateUserState();
  }, [firebaseUser, dispatch]);

  return (
    <RouterProvider router={router} />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {process.env.NODE_ENV === "production" && <Analytics />}
    </Provider>
  </React.StrictMode>
);
