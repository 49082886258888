import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config";
import config from "../config";
import AdminLayout from "../components/admin/AdminLayout";
import AdminDashboard from "../components/admin/AdminDashboard";
import UserManagement from "../components/admin/UserManagement";
import ReceiptAnalysis from "../components/admin/ReceiptAnalysis";
import Settings from "../components/admin/Settings";

export default function Admin() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [activeTab, setActiveTab] = useState("users");

  React.useEffect(() => {
    if (loading) return;
    
    if (error || !user || !config.adminEmails.includes(user.email)) {
      navigate("/");
    }
  }, [user, loading, error, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AdminLayout activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === "dashboard" && <AdminDashboard />}
      {activeTab === "users" && (
        <UserManagement
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setSelectedReceipt={setSelectedReceipt}
        />
      )}
      {activeTab === "receipts" && <ReceiptAnalysis selectedReceipt={selectedReceipt} />}
      {activeTab === "settings" && <Settings />}
    </AdminLayout>
  );
}
