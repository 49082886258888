import React from "react";
import { X, AudioLines } from "lucide-react";

interface ConversationProps {
  items: any[];
  deleteConversationItem: (id: string) => void;
}

const Conversation: React.FC<ConversationProps> = ({
  items,
  deleteConversationItem,
}) => {
  return (
    <div>
      {!items.length && `Start making dishes with FiveOut-AI...`}{" "}
      {!items.length && <AudioLines className="m-auto h-full w-20 " />}
      {items.map((conversationItem, i) => {
        return (
          conversationItem.role && (
            <div
              className="conversation-item gap-0 md:gap-4 flex flex-col sm:flex-row"
              key={conversationItem.id}
            >
              <div
                className={`speaker flex items-start mt-2 group ${conversationItem.role === "user" ? "text-green-500" : " text-blue-500"}`}
              >
                <div>
                  {(conversationItem.role || conversationItem.type).replaceAll(
                    "_",
                    " "
                  )}
                </div>
                <div
                  className="close group-hover:opacity-100 opacity-0 ml-3 border-2 cursor-pointer rounded-full border-red-600"
                  onClick={() => deleteConversationItem(conversationItem.id)}
                >
                  <X size={16} color="red" />
                </div>
              </div>
              <div className={`speaker-content`}>
                {/* tool response */}
                {conversationItem.type === "function_call_output" && (
                  <div>{conversationItem.formatted.output}</div>
                )}
                {/* tool call */}
                {!!conversationItem.formatted.tool && (
                  <div>
                    {conversationItem.formatted.tool.name}(
                    {conversationItem.formatted.tool.arguments})
                  </div>
                )}
                {!conversationItem.formatted.tool &&
                  conversationItem.role === "user" && (
                    <div>
                      {conversationItem.formatted.text?.startsWith(
                        "This is the previous conversation."
                      )
                        ? "Previous conversation is continued from here."
                        : conversationItem.formatted.transcript ||
                          (conversationItem.formatted.audio?.length
                            ? "(awaiting transcript)"
                            : conversationItem.formatted.text || "(item sent)")}
                    </div>
                  )}
                {!conversationItem.formatted.tool &&
                  conversationItem.role === "assistant" && (
                    <div>
                      {conversationItem.formatted.transcript ||
                        conversationItem.formatted.text ||
                        "(truncated)"}
                    </div>
                  )}
                {conversationItem.formatted.file && (
                  <audio src={conversationItem.formatted.file.url} controls />
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default Conversation;
