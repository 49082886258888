export const groceryCategories = [
  "Ingredients & Spices",
  "Beverages",
  "Care & Health",
  "Pet Supplies",
  "Grain Products",
  "Bread & Pastries",
  "Fruits & Vegetables",
  "Home & Garden", 
  "Milk & Cheese",
  "Own Items",
  "Meat & Fish",
  "Frozen & Convenience",
  "Snacks & Sweets",
  "Household",
  "Other",
];

export const backgroundColors = [
  { value: "bg-blue-200", label: "Blue" },
  { value: "bg-green-200", label: "Green" },
  { value: "bg-yellow-200", label: "Yellow" },
  { value: "bg-red-200", label: "Red" },
  { value: "bg-purple-200", label: "Purple" },
];

export const groceryIcons = {
  "Ingredients & Spices": "🧂",
  Beverages: "🥤",
  "Care & Health": "🧴",
  "Pet Supplies": "🐶",
  "Grain Products": "🌾",
  "Bread & Pastries": "🥐",
  "Fruits & Vegetables": "🍎",
  "Home & Garden": "🏡",
  "Milk & Cheese": "🧀",
  "Own Items": "📦",
  "Meat & Fish": "🍖",
  "Frozen & Convenience": "🥶",
  "Snacks & Sweets": "🍪",
  Household: "🧼",
  Other: "🧼",
};

export const backgroundColorsForNewList = [
  { value: "bg-blue-200", label: "Blue" },
  { value: "bg-green-200", label: "Green" },
  { value: "bg-yellow-200", label: "Yellow" },
  { value: "bg-red-200", label: "Red" },
  { value: "bg-purple-200", label: "Purple" },
];