import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "./config";

// fetch the global shopping list from the database----------------------------
export const fetchGlobalShoppingList = async () => {
  const querySnapshot = await getDocs(collection(db, "global_shopping_lists"));
  const globalItems = [];
  querySnapshot.docs.forEach((doc) => {
    globalItems.push({ id: doc.id, ...doc.data() });
  });
  return globalItems;
};

// add an item to the shopping list--------------------------------------------
export const addItemToShoppingList = async (
  userId,
  listId,
  itemName,
  globalItems
) => {
  const globalItem = globalItems.find(
    (item) =>
      item.item_name && item.item_name.toLowerCase() === itemName.toLowerCase()
  );
  const category = globalItem ? globalItem.category : "Own Items";

  const newItem = {
    item_name: itemName,
    category: category,
    date_added: serverTimestamp(),
    date_removed: null,
    quantity: 1,
    notes: globalItem ? globalItem.notes || "" : "",
  };

  console.log("Adding new item:", newItem);
  try {
    const docRef = await addDoc(
      collection(db, `shopping_lists/${listId}/items`),
      newItem
    );
    console.log("Document written with ID: ", docRef.id);

    // Update the shopping list document to include the new item
    const listRef = doc(db, `shopping_lists`, listId);
    await updateDoc(listRef, {
      items: arrayUnion(docRef.id),
    });

    console.log("Shopping list updated successfully");
    return docRef; // Ensure the item reference is returned
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error; // Re-throw the error to be caught in the calling function
  }
};

// remove an item from the shopping list----------------------------------------
export const removeItemFromShoppingList = async (
  listId,
  itemId,
  updateLocalState
) => {
  const itemRef = doc(db, `shopping_lists/${listId}/items`, itemId);
  await updateDoc(itemRef, {
    date_removed: serverTimestamp(),
  });

  // Update the local state
  if (updateLocalState) {
    updateLocalState(listId, itemId);
  }
};

// create a new shopping list---------------------------------------------------
export const createNewShoppingList = async (userId, listData) => {
  const newList = {
    ...listData,
    date_created: serverTimestamp(),
  };
  const docRef = await addDoc(collection(db, `shopping_lists`), newList);

  // Add the new list reference to the user's document
  const userRef = doc(db, `users/${userId}`);
  await updateDoc(userRef, {
    shopping_lists: arrayUnion(docRef.id),
  });

  return docRef.id;
};

// delete a shopping list-------------------------------------------------------
export const deleteShoppingList = async (userId, listId) => {
  const listRef = doc(db, `shopping_lists`, listId);
  await deleteDoc(listRef);

  // Remove the list reference from the user's document
  const userRef = doc(db, `users/${userId}`);
  await updateDoc(userRef, {
    shopping_lists: arrayRemove(listId),
  });
};
