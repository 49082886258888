import { Clock, Volume2, User, Lock } from "lucide-react";

// Reusable Feature component
const Feature = ({ Icon, title, description, link }) => (
  <div className="flex items-start space-x-3">
    <Icon className="w-6 h-6 mt-1" />
    <div>
      <p className="font-semibold">{title}</p>
      <p className="text-sm text-gray-600">
        {description}
        {link && (
          <a href="#" className="text-blue-500">
            {link}
          </a>
        )}
      </p>
    </div>
  </div>
);

export default function NewToCookWithMe({setNewToCookWithMe}) {
  const features = [
    {
      Icon: Clock,
      title: "Free monthly preview",
      description: "Use advanced voice for a short period each month.",
    },
    {
      Icon: Volume2,
      title: "Multiple Languages",
      description: "Offers an expanded set of languages to choose from.",
    },
    {
      Icon: User,
      title: "Personalized to you",
      description: "Can use memory and custom instructions to shape responses.",
    },
    {
      Icon: Lock,
      title: "You're in control",
      description:
        "Audio recordings are saved, and you can delete them at any time.",
      link: "Learn how to manage recordings.",
    },
  ];

  return (
    <div className=" flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 max-w-md w-full">
        <h1 className="text-2xl font-bold mb-4">
          Sneak a peek at Cook With Me
        </h1>

        <div className="space-y-4">
          {features.map((feature, index) => (
            <Feature
              key={index}
              Icon={feature.Icon}
              title={feature.title}
              description={feature.description}
              link={feature.link}
            />
          ))}
          <p className="text-xs sm:text-sm text-gray-600 mt-4">
            Cook with me can make mistakes — try to have less background noise.
            Usage limits may apply.
          </p>
        </div>

        <button onClick={() => setNewToCookWithMe(false)} className="mt-6 w-full bg-black text-white py-2 rounded-lg font-semibold">
          Continue
        </button>
      </div>
    </div>
  );
}
