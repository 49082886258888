export const  calculateAverageIndulgence = (items) => {
  if (items.length > 0) {
    const totalIndulgence = items.reduce(
      (sum, item) => sum + item.indulgence_rating,
      0
    );
    const averageIndulgence = totalIndulgence / items.length;
    return averageIndulgence;
  }
};

export const maxIndulgence = (items) => {
  if (items.length > 0) {
    const maxIndulgence = Math.max(...items.map((item) => item.indulgence_rating));
    return maxIndulgence;
  }
};

// to calculate total numner of categories
export const calculateTotalCategories = (items) => {
  const uniqueCategories = new Set();
  items.forEach((item) => {
    item.category?.forEach((category) => {
      uniqueCategories.add(category);
    });
  });
  return uniqueCategories.size;
};


export const setUniqueReceiptData = async (documentDetails, setDatesOfReceiptsUploaded, setDatesOfReceipts) => {

  let data = [];

  if (Array.isArray(documentDetails) && documentDetails.length > 0) {
    data = documentDetails.map((doc) => ({
      receiptUid: doc.id,
      timestamp: doc.request_timestamp,
      receiptDatetime: doc.processed_data?.datetime ?? "Unknown",
      requestData: doc,
    }));
  } else if (typeof documentDetails === 'object' && Object.keys(documentDetails).length > 0) {
    data = Object.entries(documentDetails).map(([id, doc]) => ({
      receiptUid: id,
      timestamp: doc.request_timestamp,
      receiptDatetime: doc.processed_data?.datetime ?? "Unknown",
      requestData: doc,
    }));
  }

  if (data.length > 0) {
    try {
      const uniqueReceiptDatesArray = Array.from(new Set(data.map((item) => item.receiptDatetime)));
      const uniqueDatesArray = Array.from(new Set(data.map((item) => item.timestamp)));

      setDatesOfReceiptsUploaded(uniqueDatesArray);
      setDatesOfReceipts(uniqueReceiptDatesArray);
    } catch (error) {
      console.error("Error processing receipt data:", error);
      setDatesOfReceiptsUploaded([]);
      setDatesOfReceipts([]);
    }
  } else {
    console.warn("No valid document details found");
    setDatesOfReceiptsUploaded([]);
    setDatesOfReceipts([]);
  }
};


  // Function to calculate non food items count
  export const calculateNonFoodItems = (items) => {
    return items.filter((item) => !item.edible).length;
  };

  // Function to calculate essential items count
  export const calculateEssentialItems = (items) => {
    return items.filter((item) => item.luxury_level <= 2).length;
  };

  // calculate the number of vegan items, the is a bool called is_vegan. So count the number where is_vegan is true
  export const calculateVeganItems = (items) => {
    return items.filter((item) => item.is_vegan).length;
  };

  // calculate the number of organic items, the is a bool called is_organic. So count the number where is_organic is true
  export const calculateOrganicItems = (items) => {
    return items.filter((item) => item.is_organic).length;
  };

// calculate overall sugary rating , there is a filed called  sugary_rating. so take average of it 
export const calculateSugaryRating = (items) => {
  return items.reduce((sum, item) => sum + item.sugary_rating, 0) / items.length;
};

// calculate % increase in expense compared to last month, input is object with month as key and amount as value
export const calculatePercentageIncrease = (monthlyExpenseAggregation) => {
  // current month from today
  const currentMonth = new Date().toISOString().slice(0, 7);
  const currentMonthAmount = monthlyExpenseAggregation[currentMonth];
  const lastMonthAmount = monthlyExpenseAggregation[currentMonth - 1];
  const percentageIncrease = ((currentMonthAmount - lastMonthAmount) / lastMonthAmount) * 100;

  return percentageIncrease;
};

export const processReceiptData = (receiptData) => {
  const items = receiptData.processed_data.items || [];
  return {
    store_name: receiptData.processed_data.store_name || "Unknown",
    total_amount: receiptData.processed_data.total_amount || 0,
    datetime: receiptData.processed_data.datetime || "Unknown",
    number_of_categories: calculateTotalCategories(items),
    non_food_items: calculateNonFoodItems(items),
    essential_items: calculateEssentialItems(items),
    number_of_items: items.length,
    average_indulgence: maxIndulgence(items),
    vegan_items: calculateVeganItems(items),
    organic_items: calculateOrganicItems(items),
    average_sugary_rating: calculateSugaryRating(items),
  };
};

// Function to calculate total amount from individual items
export const calculateTotalFromItems = (items) => {
  if (!items || items.length === 0) return 0;
  return items.reduce((sum, item) => sum + (parseFloat(item.total_price) || 0), 0).toFixed(2);
};
