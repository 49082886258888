// Render uploaded image
export const renderUploadedImage = (imageUrl) => (
    <div className=" px-4 m-auto mb-1 grid gap-10 place-content-center my-4 relative">
      {imageUrl && (
        <div className="image-container relative p-4 rounded-lg my-4 shadow-md flex justify-center items-center">
          {imageUrl.endsWith(".heic") ? (
            <img
              src={imageUrl.replace(".heic", ".jpg")} // Fallback for browsers that do not support HEIC
              alt="Uploaded"
              className="max-h-96 w-auto h-auto"
            />
          ) : (
            <img
              src={imageUrl}
              alt="Uploaded"
              className="max-h-96 w-auto h-auto"
            />
          )}
        </div>
      )}
    </div>
  );

