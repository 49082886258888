import React, { useState, useEffect } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../@/components/select";
import { Button } from "../../@/components/button";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../@/components/table";
import { fetchAllUsers, fetchUserReceipts, deleteReceiptByUid } from "../../utils/firestoreUtils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../@/components/tooltip";
import { ChevronUpIcon, ChevronDownIcon } from "lucide-react";
import { Trash2 } from "lucide-react";
import DeleteReceiptDialog from '../common/DeleteReceiptDialog';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import { formatDate } from "../../utils/dataFormat";
import { calculateTotalFromItems } from "../../utils/dataStats";

export default function UserManagement({ selectedUser, setSelectedUser, setSelectedReceipt }) {
  const [users, setUsers] = useState([]);
  const [userReceipts, setUserReceipts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState('upload_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [receiptToDelete, setReceiptToDelete] = useState(null);

  useEffect(() => {
    fetchAllUsers().then(setUsers);
    // Load the last selected user from localStorage
    const lastSelectedUser = localStorage.getItem('lastSelectedUser');
    if (lastSelectedUser) {
      setSelectedUser(lastSelectedUser);
    }
  }, [setSelectedUser]);

  useEffect(() => {
    if (selectedUser) {
      fetchUserReceipts(selectedUser).then(receipts => {
        sortReceipts(receipts);
      });
      localStorage.setItem('lastSelectedUser', selectedUser);
    }
  }, [selectedUser]);

  const sortReceipts = (receipts) => {
    const sortedReceipts = [...receipts].sort((a, b) => {
      let valueA, valueB;
      if (sortField === 'upload_date') {
        valueA = new Date(a.request_timestamp);
        valueB = new Date(b.request_timestamp);
      } else if (sortField === 'shopping_date') {
        valueA = new Date(a.processed_data?.datetime);
        valueB = new Date(b.processed_data?.datetime);
      } else if (sortField === 'store_name') {
        valueA = a.processed_data?.store_name || '';
        valueB = b.processed_data?.store_name || '';
        return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (sortField === 'total_amount') {
        valueA = parseFloat(a.processed_data?.total_amount) || 0;
        valueB = parseFloat(b.processed_data?.total_amount) || 0;
      } else if (sortField === 'calculated_total') {
        valueA = parseFloat(calculateTotalFromItems(a.processed_data?.items)) || 0;
        valueB = parseFloat(calculateTotalFromItems(b.processed_data?.items)) || 0;
      } else {
        valueA = a[field];
        valueB = b[field];
      }
      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    });
    setUserReceipts(sortedReceipts);
    setTotalPages(Math.ceil(sortedReceipts.length / itemsPerPage));
    setCurrentPage(1); // Reset to first page after sorting
  };

  const toggleSort = (field) => {
    if (field === sortField) {
      setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    sortReceipts(userReceipts);
  };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
    setCurrentPage(1);
    setSelectedReceipt(null);
    // Save the selected user to localStorage
    localStorage.setItem('lastSelectedUser', userId);
  };

  const handleReceiptSelect = (receipt) => {
    setSelectedReceipt(receipt);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You might want to show a temporary notification here
      console.log('Copied to clipboard');
    });
  };

  // Get current receipts
  const indexOfLastReceipt = currentPage * itemsPerPage;
  const indexOfFirstReceipt = indexOfLastReceipt - itemsPerPage;
  const currentReceipts = userReceipts.slice(indexOfFirstReceipt, indexOfLastReceipt);

  // SortableColumnHeader component
  const SortableColumnHeader = ({ label, field, currentField, currentOrder, onSort }) => {
    return (
      <button 
        onClick={() => onSort(field)}
        className="flex items-center space-x-1 hover:text-gray-900"
      >
        <span>{label}</span>
        {currentField === field && (
          currentOrder === 'asc' ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )
        )}
      </button>
    );
  };

  const handleDeleteReceipt = async () => {
    if (receiptToDelete) {
      try {
        await deleteReceiptByUid(receiptToDelete);
        console.log(`Receipt ${receiptToDelete} deleted successfully`);
        
        // Update the userReceipts state
        setUserReceipts(prevReceipts => 
          prevReceipts.filter(receipt => receipt.id !== receiptToDelete)
        );

        // If there's a selected receipt and it's the one being deleted, clear it
        if (selectedReceipt && selectedReceipt.id === receiptToDelete) {
          setSelectedReceipt(null);
        }

        // Optionally, update the total count of receipts for the current user
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user.uid === selectedUser
              ? { ...user, receiptCount: (user.receiptCount || 0) - 1 }
              : user
          )
        );

      } catch (error) {
        console.error("Error deleting receipt:", error);
        // Optionally show an error message to the user
      } finally {
        setIsDeleteDialogOpen(false);
        setReceiptToDelete(null);
      }
    }
  };

  return (
    <div className="relative">
      <div className="mb-6 z-10 relative">
        <Select onValueChange={handleUserSelect} value={selectedUser}>
          <SelectTrigger className="w-[200px] bg-white border border-gray-300 shadow-sm">
            <SelectValue placeholder="Select a user" />
          </SelectTrigger>
          <SelectContent className="bg-white border border-gray-300 shadow-lg">
            {users.map(user => (
              <SelectItem key={user.uid} value={user.uid} className="hover:bg-gray-100">
                {user.email}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      
      {selectedUser && (
        <div className="mb-6 z-0">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">User Receipts ({userReceipts.length})</h2>
          {userReceipts.length > 0 ? (
            <>
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <Table className="w-full">
                  <TableHeader>
                    <TableRow className="bg-gray-100">
                      <TableHead className="font-medium text-gray-600">
                        <SortableColumnHeader
                          label="Upload Date"
                          field="upload_date"
                          currentField={sortField}
                          currentOrder={sortOrder}
                          onSort={toggleSort}
                        />
                      </TableHead>
                      <TableHead className="font-medium text-gray-600">
                        <SortableColumnHeader
                          label="Shopping Date"
                          field="shopping_date"
                          currentField={sortField}
                          currentOrder={sortOrder}
                          onSort={toggleSort}
                        />
                      </TableHead>
                      <TableHead className="font-medium text-gray-600">
                        <SortableColumnHeader
                          label="Store Name"
                          field="store_name"
                          currentField={sortField}
                          currentOrder={sortOrder}
                          onSort={toggleSort}
                        />
                      </TableHead>
                      <TableHead className="font-medium text-gray-600">
                        <SortableColumnHeader
                          label="Total Amount"
                          field="total_amount"
                          currentField={sortField}
                          currentOrder={sortOrder}
                          onSort={toggleSort}
                        />
                      </TableHead>
                      <TableHead className="font-medium text-gray-600">
                        <SortableColumnHeader
                          label="Calculated Total"
                          field="calculated_total"
                          currentField={sortField}
                          currentOrder={sortOrder}
                          onSort={toggleSort}
                        />
                      </TableHead>
                      <TableHead className="font-medium text-gray-600">Document UID</TableHead>
                      <TableHead className="font-medium text-gray-600">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentReceipts.map(receipt => (
                      <TableRow key={receipt.id} className="hover:bg-gray-50">
                        <TableCell className="text-sm">{formatDate(receipt.request_timestamp)}</TableCell>
                        <TableCell className="text-sm">{formatDate(receipt.processed_data?.datetime)}</TableCell>
                        <TableCell className="text-sm">{receipt.processed_data?.store_name || 'N/A'}</TableCell>
                        <TableCell className="text-sm">${parseFloat(receipt.processed_data?.total_amount).toFixed(2) || 'N/A'}</TableCell>
                        <TableCell className="text-sm">
                          ${calculateTotalFromItems(receipt.processed_data?.items)}
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Button 
                              onClick={() => handleReceiptSelect(receipt)}
                              className="text-sm bg-blue-500 hover:bg-blue-600 text-white"
                            >
                              View
                            </Button>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    onClick={() => {
                                      setReceiptToDelete(receipt.id);
                                      setIsDeleteDialogOpen(true);
                                    }}
                                    variant="destructive"
                                    className="text-sm"
                                  >
                                    <Trash2 size={16} />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Delete this receipt</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <Button 
                  onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))} 
                  disabled={currentPage === 1}
                  className="text-sm bg-gray-200 hover:bg-gray-300 text-gray-700"
                >
                  Previous
                </Button>
                <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>
                <Button 
                  onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))} 
                  disabled={currentPage === totalPages}
                  className="text-sm bg-gray-200 hover:bg-gray-300 text-gray-700"
                >
                  Next
                </Button>
              </div>
            </>
          ) : (
            <p className="text-gray-600">No receipts available for this user.</p>
          )}
        </div>
      )}
      <DeleteReceiptDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDeleteReceipt}
      />
    </div>
  );
}
