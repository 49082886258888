import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../../../@/components/card";
import { Button } from "../../../@/components/button";

const TryFiveOutChat = () => {
  return (
    <Card className="max-w-sm w-full h-full">
      <CardHeader className="pb-3 ">
        <CardTitle className="font-bold tracking-normal text-zinc-600">
          FiveOut Chat
        </CardTitle>
        <CardDescription className="max-w-lg font-[500] text-zinc-500 leading-relaxed">
          Try our new AI chat to ask questions about your receipts.
        </CardDescription>
      </CardHeader>
      <CardFooter className="flex justify-center">
        <Button
          onClick={() => window.open("https://talk.5outapp.com", "_blank")}
          className="w-full"
        >
          Try it now !
        </Button>
      </CardFooter>
    </Card>
  );
};

export default TryFiveOutChat;
