import React from "react";
import { Home, Users, FileText, Settings } from "lucide-react";

const navItems = [
  { id: "dashboard", icon: Home, label: "Dashboard" },
  { id: "users", icon: Users, label: "User Management" },
  { id: "receipts", icon: FileText, label: "Receipt Analysis" },
  { id: "settings", icon: Settings, label: "Settings" },
];

export default function Sidebar({ activeTab, setActiveTab }) {
  return (
    <aside className="w-64 bg-white shadow-md">
      <nav className="mt-5 px-2">
        {navItems.map((item) => (
          <a
            key={item.id}
            href="#"
            className={`group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md ${
              activeTab === item.id
                ? "text-gray-900 bg-gray-100"
                : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(item.id);
            }}
          >
            <item.icon
              className={`mr-4 h-6 w-6 ${
                activeTab === item.id
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500"
              }`}
            />
            {item.label}
          </a>
        ))}
      </nav>
    </aside>
  );
}
