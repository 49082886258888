import React from "react";
import GroceryListViewer from "../components/track-foods/groceries list/GroceryListViewer";
export default function GroceryList() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow overflow-auto">
        <GroceryListViewer />
      </div>
    </div>
  );
}




