import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../../../@/components/card";
import { UploadImage } from "../../../pages/HomeNew";

const AddReceiptCard = () => {
  return (
    <Card className="sm:col-span-2">
      <CardHeader className="pb-3">
        <CardTitle>Your Receipts</CardTitle>
        <CardDescription className="text-balance  leading-relaxed">
          Introducing Our Dynamic Orders Dashboard for Seamless Management and
          Insightful Analysis.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <UploadImage buttonText="Add more" />
      </CardFooter>
    </Card>
  );
};

export default AddReceiptCard;
