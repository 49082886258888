import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../@/components/card";
import { Badge } from "../@/components/badge";

const roadmapData = {
  "roadmap": {
    "inProgress": [
      {
        "title": "Manual Image Correction Feature",
        "description": "Implement a feature allowing users to manually correct image processing errors, providing users with the ability to make corrections themselves as a temporary solution until further improvements are made."
      }
    ],
    "planned": [
      {
        "title": "Nearby Supermarket Finder",
        "description": "Develop a tool to help users find nearby supermarkets that are both affordable and in close proximity. This tool will provide users with a visual representation of nearby supermarkets to assist them in making informed decisions about where to shop."
      }
    ],
    "completed": [
      {
        "title": "3x accuracy in reading receipts",
        "description": "New algorithm to read receipts with 3x accuracy, also make it faster",
        "date": "10th August 2024"
      },
      {
        "title": "Correct the email authentication",
        "description": "Correcting the email authentication issue reported by one of our users, Max, to ensure secure access and enhance user experience.",
        "date": "10th August 2024"
      },
      {
        "title": "Banana Mascot Landing Page Redesign",
        "description": "Redesigned the landing page with a banana mascot theme for a fresh and engaging look",
        "date": "20th July 2024"
      },
      {
        "title": "Recipe Suggestions based on Grocery Bill",
        "description": "Tool now suggests three recipes based on the scanned grocery bill for user convenience",
        "date": "10th July 2024"
      },
      {
        "title": "Post-Scan Recommendations",
        "description": "Provide recommendations to the user after scanning the shopping receipt to highlight what was done well and suggest improvements for next time.",
        "date": "10th July 2024"
      }
    ]
  }
}

const FeatureCard = ({ title, description, status, date }) => (
  <Card className="mb-4 w-full hover:shadow-lg transition-shadow duration-300">
    <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 pb-2">
      <CardTitle className="text-base sm:text-lg font-semibold">{title}</CardTitle>
      <Badge 
        variant={date ? "secondary" : status === "Planned" ? "outline" : "default"}
        className={`px-2 py-1 text-xs font-medium ${status === "In Progress" ? "bg-blue-500 text-white" : ""} mt-1 sm:mt-0`}
      >
        {date ? date : status}
      </Badge>
    </CardHeader>
    <CardContent>
      <CardDescription className="text-sm text-gray-600">{description}</CardDescription>
    </CardContent>
  </Card>
);

const FeatureSection = ({ title, features, status }) => (
  <div className={`mb-8 ${status === "In Progress" ? "bg-blue-50 p-4 sm:p-6 rounded-lg" : ""}`}>
    <h2 className={`text-xl sm:text-2xl font-bold mb-4 ${status === "In Progress" ? "text-blue-700" : ""}`}>{title}</h2>
    <div className="space-y-4">
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          title={feature.title}
          description={feature.description}
          status={status}
          date={feature.date}
        />
      ))}
    </div>
  </div>
);

const Roadmap = () => {
  const { inProgress, planned, completed } = roadmapData.roadmap;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow overflow-auto">
        <div className="mx-auto p-4 max-w-4xl">
          <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-10 text-center">Product Roadmap</h1>
          <div className="space-y-8 sm:space-y-12">
            <FeatureSection title="In Progress" features={inProgress} status="In Progress" />
            <FeatureSection title="Planned" features={planned} status="Planned" />
            <FeatureSection title="Completed" features={completed} status="Completed" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;