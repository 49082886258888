import { Link, NavLink, Outlet } from "react-router-dom";
import "../../globals.css";
import Footer from "./Footer";
import { auth } from "../../firebase/config"; // Assuming you have the auth object exported from your Firebase configuration
import { useNavigate } from "react-router-dom";
import { Button } from "../../@/components/button";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Navbar() {
  const [user] = useAuthState(auth); // Check if the user is logged in
  const navigate = useNavigate(); // Access the navigate function
  const handleLogout = () => {
    localStorage.removeItem("email");
    auth.signOut().then(() => {
      // Redirect to the main page
      navigate("/", { replace: true });
    });
  };

  return (
    <>
      <nav className="sticky top-0 z-50 bg-transparent backdrop-blur-sm border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold">FiveOut</Link>
          
          <div className="hidden md:flex space-x-6">
            <NavLink to="/#demo-section" className="hover:text-gray-600 transition-colors">Demo</NavLink>
            <NavLink to="/#how-it-works" className="hover:text-gray-600 transition-colors">How It Works</NavLink>
            <NavLink to="/#faq-section" className="hover:text-gray-600 transition-colors">FAQs</NavLink>
          </div>
          
          <div className="flex space-x-4">
            <Button variant="outline" className="hover:bg-gray-100/50">
              {user ? (
                <NavLink to="/dashboard">Dashboard</NavLink>
              ) : (
                <NavLink to="/">Track your Food</NavLink>
              )}
            </Button>
            
            <Button variant="default" className="hover:bg-gray-800">
              {user ? (
                <NavLink onClick={handleLogout}>Logout</NavLink>
              ) : (
                <NavLink to="/login">Login</NavLink>
              )}
            </Button>
          </div>
        </div>
      </nav>
      
      <main className="min-h-screen">
        <Outlet />
      </main>
      
      <Footer />
    </>
  );
}