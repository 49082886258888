import apiClient from '../apiClient';
import endpoints from '../endpoints';

export const extractText = async (key,collectionName = "groceries_temp") => {
  try {
    const response = await apiClient.post(endpoints.extractText, null, {
      params: {
        key,
        collection_name: collectionName,
      },
    });
    console.log(`Extract Text API call successful`);
    return response;
  } catch (error) {
    console.error(`Error in Extract Text API call:`, error);
    throw error;
  }
};
