import React, { useState, useEffect } from "react";
import {
  fetchGlobalShoppingList,
  addItemToShoppingList,
} from "../../../firebase/grocerylistUtils";
import { Search } from "lucide-react";
import { groceryIcons } from "./groceryData";

const GrocerySearch = ({ userId, listId, setSelectedList, setIsLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [globalItems, setGlobalItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const items = await fetchGlobalShoppingList();
      setGlobalItems(items);
    };
    fetchItems();
  }, []);

  const handleAddItem = async (itemName) => {
    setIsLoading(true);
    try {
      const itemRef = await addItemToShoppingList(
        userId,
        listId,
        itemName,
        globalItems
      );

      if (!itemRef) {
        throw new Error("Failed to add item to shopping list");
      }

      // Update the local state
      const newItemData = {
        item_id: itemRef.id,
        item_name: itemName,
        category:
          globalItems.find((item) => item.item_name === itemName)?.category ||
          "Own Items",
        notes: "",
        quantity: 1,
        date_added: new Date().toISOString(),
      };

      setSelectedList((prevList) => ({
        ...prevList,
        items: [...prevList.items, newItemData],
      }));

      setSearchTerm("");
    } catch (error) {
      console.error("Error adding item to list:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-lg mx-auto my-6 relative">
      <div className="mb-4">
        <div className="flex justify-between bg-white px-4 py-1 h-10 border items-center rounded-lg shadow-sm">
          <Search className="mr-2" />
          <input
            type="text"
            className="w-full  outline-none text-sm"
            placeholder="I want to add ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {searchTerm.length > 0 && (
        <div className="bg-white absolute top-12 left-0 w-full z-[999]  border rounded-lg shadow-sm max-h-64 overflow-auto">
          {globalItems
            .filter((item) =>
              item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((item, index) => (
              <div
                key={index}
                className="border-b p-2  hover:bg-gray-100 cursor-pointer text-sm"
                onClick={() => handleAddItem(item.item_name)}
              >
                <div className="font-medium">{item.item_name}</div>
                <div className="text-sm text-gray-500">
                  <span>{groceryIcons[item.category]}</span> {item.category}
                </div>
              </div>
            ))}
          <div
            className="p-4 py-3 bg-white cursor-pointer text-sm"
            onClick={() => handleAddItem(searchTerm)}
          >
            {searchTerm}
          </div>
        </div>
      )}
    </div>
  );
};

export default GrocerySearch;
