import { ShoppingCart, ChevronLeft } from "lucide-react";
import { useEffect } from "react";
import { Card, CardTitle, CardContent } from "../../../@/components/card.js";
import GrocerySearch from "./GrocerySearch.jsx";
import { removeItemFromShoppingList } from "../../../firebase/grocerylistUtils.js";
import { groceryIcons } from "./groceryData.js";
const SelectedListViewer = ({
  selectedList,
  setSelectedList,
  setIsLoading,
  updateListInParent, // Receive the update function
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleItemClick = (item) => {
    setIsLoading(true);
    removeItemFromShoppingList(
      selectedList.id,
      item.item_id,
      (listId, itemId) => {
        const updatedList = {
          ...selectedList,
          items: selectedList.items.filter((item) => item.item_id !== itemId),
        };
        setSelectedList(updatedList);
        updateListInParent(updatedList); // Update the parent state
      }
    );
    setIsLoading(false);
  };

  return (
    <div className={`w-full`}>
      <button
        onClick={() => setSelectedList(null)}
        className="text-white bg-gradient-to-r from-purple-600 mb-6 ml-6 to-indigo-600 font-semibold mr-auto text-xs sm:text-sm rounded-full py-1 pl-1 pr-3 flex items-center"
      >
        <ChevronLeft className="mr-1 text-xs sm:text-sm" />
        Back To Lists
      </button>{" "}
      <div className={`w-full h-full pt-0  sm:p-6 `}>
        <h2
          className={`text-xl font-bold text-center py-2 flex items-center justify-center border-b border-gray-400 pb-3  `}
        >
          <ShoppingCart className="mr-2 inline" />

          {selectedList?.name}
        </h2>
        <p className="text-gray-600 text-xs hidden sm:block text-right">
          Created:{" "}
          {selectedList?.date_created instanceof Date
            ? selectedList.date_created.toLocaleDateString()
            : selectedList?.date_created &&
                typeof selectedList.date_created.toDate === "function"
              ? selectedList.date_created.toDate().toLocaleDateString()
              : "Unknown date"}
        </p>
        <GrocerySearch
          userId={selectedList.userId}
          listId={selectedList.id}
          setSelectedList={setSelectedList}
          setIsLoading={setIsLoading}
        />
        {selectedList?.items?.length > 0 && (
          <div className="my-10 ">
            <div className=" max-w-2xl mx-auto grid grid-cols-1  gap-4">
              {Object.entries(
                selectedList.items.reduce((acc, item) => {
                  if (!acc[item.category]) acc[item.category] = [];
                  acc[item.category].push(item);
                  return acc;
                }, {})
              ).map(
                ([category, items]) =>
                  items.length > 0 &&
                  category !== "undefined" && (
                    <Card
                      key={category}
                      className={`${selectedList.backgroundColor} rounded-xl h-full p-1 `}
                    >
                      <CardTitle className="font-semibold text-gray-900 text-lg px-4 py-2 flex justify-between items-center">
                        <span>
                          {groceryIcons[category]} {category}
                        </span>
                      </CardTitle>
                      <CardContent className="bg-white/80 rounded-lg p-2 sm:p-4">
                        <ul className="space-y-3 mt-1">
                          {items
                            .filter((item) => !item.date_removed) // Only show items without date_removed
                            .map((item) => (
                              <li
                                key={item.item_id}
                                className="flex justify-between border relative bg-white py-2 px-4 rounded-lg hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleItemClick(item)} // Use the new handler
                              >
                                <div className="flex flex-col">
                                  <span className="font-semibold">
                                    {item.item_name}
                                  </span>
                                  <span className="text-gray-600">
                                    {item.notes}
                                  </span>
                                </div>
                                <span className="bg-gray-600 font-semibold text-white text-xs px-4 py-1 h-fit rounded-full">
                                  {item.quantity || "1"}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </CardContent>
                    </Card>
                  )
              )}
            </div>
          </div>
        )}

        {selectedList?.items?.length === 0 && (
          <div className="text-center text-gray-500">
            <p>No items in this list.</p>
            <p>Add items to get started via the search bar above.!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedListViewer;
