import { useEffect, useState } from "react";
import { getFirebaseUrl } from "../../utils/firestoreUtils";

const PreviousConversation = ({ items }) => {
  const [audioUrls, setAudioUrls] = useState([]);

  useEffect(() => {
    const fetchAudioUrls = async () => {
      const urls = [];
      for (const item of items) {
        if (item.audio_url) {
          const url = await getFirebaseUrl(item.audio_url);

          urls.push(url);
        } else {
          urls.push("");
        }
      }
      setAudioUrls(urls);
    };
    if (items.length > 0) {
      fetchAudioUrls();
    }
  }, [items]);

  return (
    <div>
      {items.map((conversationItem, idx) => {
        const audioUrl = audioUrls[idx];

        return (
          conversationItem.role && (
            <div
              className="conversation-item gap-0 md:gap-4 flex flex-col sm:flex-row"
              key={idx}
            >
              <div
                className={`speaker flex items-start mt-2 group ${
                  conversationItem.role === "user"
                    ? "text-green-500"
                    : "text-blue-500"
                }`}
              >
                <p className="mr-6">{conversationItem.role}</p>
              </div>
              <div className={`speaker-content`}>
                <div>
                  {conversationItem.text?.startsWith(
                    "This is the previous conversation."
                  )
                    ? "Previous conversation is continued from here."
                    : conversationItem.text}
                </div>
                {audioUrl ? <audio src={audioUrl} controls /> : <p></p>}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default PreviousConversation;
