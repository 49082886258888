import React from "react";
import Sidebar from "./Sidebar";

export default function AdminLayout({ children, activeTab, setActiveTab }) {
  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex-1 p-10">
        <main>{children}</main>
      </div>
    </div>
  );
}
