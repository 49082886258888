import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { isSignInWithEmailLink, sendSignInLinkToEmail } from "firebase/auth";
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  Card,
} from "../../@/components/card";
import { Label } from "../../@/components/label";
import { Input } from "../../@/components/input";
import { Button } from "../../@/components/button";
import {
  signInWithGoogle,
  completeSignInWithEmailLink,
} from "../../firebase/authUtils";

export const AuthShadcnNew = () => {
  const [email, setEmail] = useState("");
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [infoMsg, setInfoMsg] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/login");
    } else if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = localStorage.getItem("email");
      if (!email) {
        email = window.prompt("Please confirm your email for sign-in.");
        localStorage.setItem("email", email);
      }

      setInitialLoading(true);
      completeSignInWithEmailLink(email)
        .then(() => {
          setInitialLoading(false);
          setEmailVerified(true);
          navigate("/login");
        })
        .catch((err) => {
          console.error(err);
          setInitialLoading(false);
          setInitialError(err.message);
          navigate("/login");
        });
    }
  }, [user, navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    const url = process.env.NODE_ENV === "production"
      ? "https://www.5outapp.com/login"
      : "http://localhost:3000/login";

    sendSignInLinkToEmail(auth, email, {
      url,
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem("email", email);
        setLoginLoading(false);
        setInfoMsg("We have sent you an email with a link to sign in");
      })
      .catch((err) => {
        setLoginLoading(false);
        setLoginError(err.message);
      });
  };

  useEffect(() => {
    if (emailVerified && user) {
      localStorage.setItem("email", email);
      localStorage.setItem("userUid", user.uid);
    }
  }, [emailVerified, email, user]);

  if (emailVerified) {
    return (
      <div className="text-center">
        <p>Thanks for verifying the email</p>
        <Button onClick={() => navigate(`/dashboard`)}>Go to Dashboard</Button>
      </div>
    );
  }

  return (
    <div className="p-4">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          {initialLoading ? (
            <div>Loading...</div>
          ) : initialError ? (
            <div className="error-msg">{initialError}</div>
          ) : user ? (
            <div>
              Please wait...
              <Button onClick={() => navigate(`/dashboard`)}>Go to Dashboard</Button>
            </div>
          ) : (
            <div className="grid gap-8">
              <form className="form-group custom-form" onSubmit={handleLogin}>
                <div className="grid gap-4">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="email"
                    required
                    placeholder="joe.musk@gmail.com"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <Button type="submit" className="w-full mt-4">
                  {loginLoading ? "Logging you in" : "Login"}
                </Button>
                {loginError && <div className="error-msg">{loginError}</div>}
                {infoMsg && <div className="info-msg">{infoMsg}</div>}
              </form>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t"></span>
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">Or continue with</span>
                </div>
              </div>
              <Button
                className="w-full"
                variant="outline"
                onClick={signInWithGoogle}
              >
                Login with Google
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
