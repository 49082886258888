
import { Skeleton } from "../../@/components/skeleton";
import { Card, CardContent, CardHeader } from "../../@/components/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/table";

export const SkeletonReceiptSummary = () => (
  <div className="w-11/12 max-w-3xl m-auto text-center flex flex-col items-center gap-10">
    <div className="grid grid-cols-2 grid-rows-6 gap-4">
      {[...Array(9)].map((_, i) => (
        <div key={i}>
          <CardHeader className="pb-2 text-left">
            <Skeleton className="h-4 w-[150px]" />
            <Skeleton className="h-10 w-[100px] mt-2" />
          </CardHeader>
        </div>
      ))}
    </div>
  </div>
);

export const SkeletonInvoiceTable = () => (
  <Card className="transition-all duration-300 hover:shadow-lg hover:-translate-y-1 bg-white max-w-3xl w-full">
    <CardHeader>
      <Skeleton className="h-8 w-[200px] mx-auto" />
    </CardHeader>
    <CardContent>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <Skeleton className="h-4 w-[100px]" />
            </TableHead>
            <TableHead>
              <Skeleton className="h-4 w-[100px]" />
            </TableHead>
            <TableHead>
              <Skeleton className="h-4 w-[100px]" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(5)].map((_, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton className="h-4 w-[150px]" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-[100px]" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-[80px]" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export const SkeletonUploadImage = () => (
  <div className="flex flex-col items-center space-y-4">
    <Skeleton className="h-64 w-64 rounded-lg" /> {/* Placeholder for image */}
    <Skeleton className="h-10 w-32 rounded-3xl" />{" "}
    {/* Placeholder for button */}
    <Skeleton className="h-4 w-48" /> {/* Placeholder for text */}
  </div>
);

export const LoadingSpinner = () => (
  <div className="h-screen w-full fixed top-0 left-0  bg-neutral-900/40 z-[9998] grid place-items-center">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/parts-1ffae.appspot.com/o/icons%2Floading.png?alt=media&token=6773e6d3-199b-4ae1-babf-d2ce6c552816"
      alt="logo"
      className="animate-spin "
      width={100}
      height={100}
    />
  </div>
);
