const HowItWorks = () => (
  <div id="how-it-works" className="w-full max-w-5xl m-auto my-20 p-4">
    <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6 text-center">
      How it works
    </h2>
    <div className="text-lg py-4">
      <p className="mt-4">
        Just upload the grocery receipt which you have received from the store.
        It will analyze for you and provide some key statistics on it.
      </p>

      <p className="mt-4">
        If you sign up, you can keep track of all the receipts you have
        uploaded so far.
      </p>

      <p className="mt-4">
        Cool new feature will be coming out to suggest you to eat better while
        not spend too much.
      </p>
    </div>


  </div>
);

export default HowItWorks;
