import React, { useState, useEffect } from "react";
import { CardHeader, CardDescription, CardTitle } from "../../@/components/card";
import { formatDate } from "../../utils/dataFormat";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";
import { processReceiptData } from "../../utils/dataStats";

const MetricCard = ({ description, value, itemChange }) => (
  <div>
    <CardHeader className="pb-2 text-left">
      <CardDescription>{description}</CardDescription>
      <CardTitle className="text-4xl">
        {value}
        {itemChange !== undefined && (
          <span className={`text-sm ${itemChange === null ? "text-gray-500" : itemChange >= 0 ? "text-green-500" : "text-red-500"}`}>
            {itemChange === null ? "(login)" : `(${itemChange >= 0 ? "+" : ""}${itemChange})`}
          </span>
        )}
      </CardTitle>
    </CardHeader>
  </div>
);

const ReceiptSummary = ({ receiptKey }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawData = await fetchReceiptDetails(receiptKey, "groceries_temp");;
        const processedData = processReceiptData(rawData);
        setData(processedData);
      } catch (err) {
        console.error("Error fetching receipt data:", err);
        setError("Failed to load receipt data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [receiptKey]);

  if (loading) return <p>Loading receipt summary...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!data) return null;


  return (
    <div className="w-11/12 max-w-3xl m-auto text-center flex flex-col items-center gap-10">
      <div className="grid grid-cols-2 grid-rows-6 gap-4">
        <MetricCard description="Number of Items" value={data.number_of_items} />
        <MetricCard description={`Total Amount($)`} value={data.total_amount} />
        <MetricCard description="Number of Categories" value={data.number_of_categories} />
        <MetricCard description="Date of Shopping" value={formatDate(data.datetime)} />
        <MetricCard description="Number of Luxury Items" value={data.number_of_items - data.essential_items} />
        <MetricCard description="Average Sugary Rating(0-5)" value={data.average_sugary_rating.toFixed(1)} />
        <MetricCard description="Number of Food Items" value={data.number_of_items - data.non_food_items} />
        <MetricCard description="Number of Vegan Items" value={data.vegan_items} />
        <MetricCard description="Number of Organic Items" value={data.organic_items} />
      </div>
    </div>
  );
};

export default ReceiptSummary;