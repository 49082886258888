import { useEffect, useRef, useCallback, useState } from "react";

import { RealtimeClient } from "../lib/realtime-api-beta/index.js";
import { ItemType } from "../lib/realtime-api-beta/dist/lib/client.js";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";
import { instructions } from "../utils/conversation_config.js";
import { WavRenderer } from "../utils/wav_renderer";

import "../components/cookWithMe/cookWithMe.scss";
import { LoadingSpinner } from "../components/common/SkeletonLoading.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  saveConversation,
  uploadAudioToStorage,
} from "../utils/firestoreUtils.js";
import NewToCookWithMe from "../components/cookWithMe/NewToCookWithMe.jsx";

import ToggleMenu from "../components/cookWithMe/ToggleMenu.jsx";
import Conversation from "../components/cookWithMe/Conversation";
import ListeningIndicator from "../components/cookWithMe/ListeningIndicator.jsx";
import ConversationControls from "../components/cookWithMe/ConversationControls";
import PreviousConversation from "../components/cookWithMe/PreviousConversation.jsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config.js";

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: "client" | "server";
  count?: number;
  event: { [key: string]: any };
}
// Define the type for messageData
type MessageData = {
  role: any;
  text: any;
  timestamp: string;
  audio_url?: string; // Add audio_url as an optional property
};

export default function CookWithMe() {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);

  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [newToCookWithMe, setNewToCookWithMe] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState({
    conversation: [],
  });
  const { user } = useSelector((state: any) => state.user);
  // const [user] = useAuthState(auth);
  // const {data:user} = g
  // State to manage the active view
  const [activeView, setActiveView] = useState<"conversation" | "listening">(
    "listening"
  );
  let conversationId = "";
  const navigate = useNavigate();
  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(
    async (pastConversation?: any, user?: any) => {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      startTimeRef.current = new Date().toISOString();
      setIsConnected(true);
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();

      if (pastConversation && Array.isArray(pastConversation.conversation)) {
        console.log("selectedConversation", pastConversation);
        conversationId = pastConversation.id;

        const conversationText = pastConversation.conversation
          .map((item: any) => item.text)
          .filter(Boolean)
          .join(" ");

        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `This is the previous conversation. Please continue from here. ${conversationText}`,
          },
        ]);
      } else {
        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Hello!`,
          },
        ]);
      }

      client.on("conversation.updated", async ({ item, delta }: any) => {
        const items = client.conversation.getItems();
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === "completed" && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
        setItems(items);
        if (item.status === "completed" && item.role === "assistant") {
          console.log("working");
          const lastItem = items[items.length - 1];
          let itemWithRole = null;

          // Loop through items backward to find the first object with a role property
          for (let i = items.length - 2; i >= 0; i--) {
            if (items[i].role) {
              itemWithRole = items[i];
              break;
            }
          }

          const itemsToFormat = itemWithRole
            ? [itemWithRole, lastItem]
            : [lastItem];

          formatConversationForFirestore(
            itemsToFormat,
            localStorage.getItem("userUid") || ""
          ).then((conversation) => {
            if (conversation.length > 0) {
              console.log("conversationId", conversationId);
              saveConversation(conversation, conversationId).then((talkId) => {
                if (talkId) {
                  conversationId = talkId;
                }
              });
            }
          });
        }
      });
      setItems(client.conversation.getItems());

      if (client.getTurnDetectionType() === "server_vad") {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
    },
    []
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Function to extract data and convert it to Firestore format
  const formatConversationForFirestore = async (
    localState: any,
    userUid: string
  ) => {
    // Initialize the conversation array
    let conversation = [];

    for (let i = 0; i < localState.length; i++) {
      const item = localState[i];
      const formatted = item.formatted;

      let messageData: MessageData = {
        role: item.role,
        text: formatted.text || formatted.transcript, // If no 'text', fallback to 'transcript'
        timestamp: new Date().toISOString(),
      };

      // If there's an audio file, upload it to Firebase Storage and store its path
      if (formatted.audio && formatted.file && formatted.file.blob) {
        const audioPath = await uploadAudioToStorage(
          formatted.file.blob,
          userUid,
          conversationId || "new_conversation", // Use conversationId or a default
          item.role,
          `${new Date()}`
        );

        messageData.audio_url = audioPath; // Save audio path in the message data
      }

      // Push the formatted message data to the conversation array
      conversation.push(messageData);
    }

    return conversation; // This array will be used to save the conversation in Firestore
  };

  useEffect(() => {
    if (!user?.betaTester) {
      // navigate("/dashboard");
    } else {
      if (user?.cookwithme_talks?.length > 0) {
        setNewToCookWithMe(false);
      }
    }
  }, [user]);
  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});
    setSelectedConversation({ conversation: [] });
    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, [items]); // Add items as a dependency

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === "none" && wavRecorder.getStatus() === "recording") {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === "none" ? null : { type: "server_vad" },
    });
    if (value === "server_vad" && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === "none");
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll("[data-conversation-content]")
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext("2d");
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              "#0099ff",
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext("2d");
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              "#009900",
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });

    // Add tools
    client.addTool(
      {
        name: "set_memory",
        description: "Saves important data about the user into memory.",
        parameters: {
          type: "object",
          properties: {
            key: {
              type: "string",
              description:
                "The key of the memory value. Always use lowercase and underscores, no other characters.",
            },
            value: {
              type: "string",
              description: "Value can be anything represented as a string",
            },
          },
          required: ["key", "value"],
        },
      },
      async ({ key, value }: { [key: string]: any }) => {
        setMemoryKv((memoryKv) => {
          const newKv = { ...memoryKv };
          newKv[key] = value;
          return newKv;
        });
        return { ok: true };
      }
    );

    // handle realtime events from client + server for event logging
    client.on("realtime.event", (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      disconnectConversation();
      event.preventDefault();
      event.returnValue = ""; // This is required for some browsers to show the confirmation dialog
    };
    if (isConnected) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (isConnected) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, []);

  // -----------------------------------------------
  if (newToCookWithMe) {
    return <NewToCookWithMe setNewToCookWithMe={setNewToCookWithMe} />;
  }
  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage" className="relative pb-9 sm:pb-0">
      <div className="flex items-center justify-between gap-2 mb-4 sticky  bg-[#f4f2ee] z-10 top-0">
        <h1 className="text-2xl font-bold  text-center">Cook With Me</h1>
        <ToggleMenu
          changeTurnEndType={changeTurnEndType}
          activeView={activeView}
          setActiveView={setActiveView}
          isConnected={isConnected}
          setSelectedConversation={setSelectedConversation}
          connectConversation={connectConversation}
          disconnectConversation={disconnectConversation}
        />
      </div>{" "}
      {isConnected && items.length === 0 && <LoadingSpinner />}
      <div className="content-main">
        <div className="content-logs">
          {/* Conditional Rendering Based on Active View */}

          {activeView === "conversation" && (
            <div className="content-block conversation pb-24 sm:pb-0">
              <div className="content-block-body" data-conversation-content>
                {selectedConversation && (
                  <PreviousConversation
                    items={selectedConversation?.conversation}
                  />
                )}
                <Conversation
                  items={items}
                  deleteConversationItem={deleteConversationItem}
                />
              </div>
            </div>
          )}
          {activeView === "listening" && <ListeningIndicator items={items} />}
          <ConversationControls
            items={items}
            isConnected={isConnected}
            canPushToTalk={canPushToTalk}
            isRecording={isRecording}
            startRecording={startRecording}
            stopRecording={stopRecording}
            connectConversation={connectConversation}
            disconnectConversation={disconnectConversation}
          />
        </div>
      </div>
    </div>
  );
}
