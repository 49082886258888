import React, { useState, useEffect, useCallback } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "../../@/components/card";
import { Button } from "../../@/components/button";
import { Input } from "../../@/components/input";
import { ZoomIn, ZoomOut, Copy, RefreshCw, Search, Trash2, AlertTriangle } from "lucide-react";
import { getFirebaseImageUrl } from "../../firebase/config";
import { fetchTextFileContent, fetchReceiptByUid, deleteReceiptByUid, updateReceiptField } from "../../utils/firestoreUtils";
import InvoiceTable from "../../components/track-foods/InvoiceTable";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../@/components/tooltip";
import { callLlmStructured } from "../../api/services/llmService";
import { extractText } from "../../api/services/textService";
import { ErrorBoundary } from 'react-error-boundary';
import DeleteReceiptDialog from '../common/DeleteReceiptDialog';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import { formatDate } from '../../utils/dataFormat';
import { parseISO, isValid, format } from 'date-fns';
import EditableField from '../common/EditableField';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default function ReceiptAnalysis({ selectedReceipt, onReceiptUpdate }) {
  const [receiptUidInput, setReceiptUidInput] = useState("");
  const [receiptData, setReceiptData] = useState(selectedReceipt);
  const [imageUrl, setImageUrl] = useState(null);
  const [ocrText, setOcrText] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [recalculationSuccess, setRecalculationSuccess] = useState(false);
  const [isRegeneratingOcr, setIsRegeneratingOcr] = useState(false);
  const [ocrRegenerationSuccess, setOcrRegenerationSuccess] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isEditingStoreName, setIsEditingStoreName] = useState(false);
  const [editedStoreName, setEditedStoreName] = useState("");

  const loadReceiptData = useCallback(async (receipt) => {
    if (receipt?.image_url) {
      const imgUrl = await getFirebaseImageUrl(receipt.image_url);
      setImageUrl(imgUrl);
    }
    
    if (receipt?.processed_text_file) {
      const textContent = await fetchTextFileContent(receipt.processed_text_file);
      setOcrText(textContent);
    }
  }, []);

  useEffect(() => {
    if (selectedReceipt) {
      setReceiptData(selectedReceipt);
      loadReceiptData(selectedReceipt);
    }
  }, [selectedReceipt, loadReceiptData]);

  const handleZoomIn = () => setZoom(prevZoom => prevZoom + 0.1);
  const handleZoomOut = () => setZoom(prevZoom => Math.max(0.1, prevZoom - 0.1));

  const handleRecalculate = async () => {
    setIsRecalculating(true);
    setRecalculationSuccess(false);
    try {
      const updatedReceipt = await callLlmStructured(receiptData.id, "allUserReceipts");
      
      if (updatedReceipt && updatedReceipt.processed_data) {
        setReceiptData(prevData => ({
          ...prevData,
          ...updatedReceipt
        }));
        setRecalculationSuccess(true);
        if (onReceiptUpdate) {
          onReceiptUpdate(updatedReceipt);
        }
      } else {
        console.error("Recalculation did not return valid data:", updatedReceipt);
        throw new Error("Invalid data received from recalculation");
      }
    } catch (error) {
      console.error("Error recalculating structured data:", error);
      // Optionally, you can set an error state here to display to the user
      // setRecalculationError(error.message);
    } finally {
      setIsRecalculating(false);
    }
  };

  const handleRegenerateOcr = async () => {
    setIsRegeneratingOcr(true);
    setOcrRegenerationSuccess(false);
    try {
      if (!receiptData || !receiptData.id) {
        throw new Error("No receipt selected");
      }
      const response = await extractText(receiptData.id, "allUserReceipts");
      if (response && response.data) {
        setOcrText(response.data);
        setOcrRegenerationSuccess(true);
      }
    } catch (error) {
      console.error("Error regenerating OCR:", error);
      // Optionally, set an error state to display
    } finally {
      setIsRegeneratingOcr(false);
    }
  };

  // Add this new function to handle loading a receipt by UID
  const handleLoadReceiptByUid = async (e) => {
    e.preventDefault();
    if (receiptUidInput) {
      try {
        const fetchedReceipt = await fetchReceiptByUid(receiptUidInput);
        if (fetchedReceipt) {
          setReceiptData(fetchedReceipt);
          await loadReceiptData(fetchedReceipt);
          if (onReceiptUpdate) {
            onReceiptUpdate(fetchedReceipt); // This updates the selectedReceipt in the parent component
          }
          setReceiptUidInput(""); // Clear the input after successful load
        } else {
          console.error("Receipt not found");
          // Optionally, set an error state to display to the user
        }
      } catch (error) {
        console.error("Error loading receipt:", error);
        // Optionally, set an error state to display to the user
      }
    }
  };

  const handleDeleteReceipt = async () => {
    if (receiptData && receiptData.id) {
      try {
        await deleteReceiptByUid(receiptData.id);
        console.log(`Receipt ${receiptData.id} deleted successfully`);
        // Clear the current receipt data
        setReceiptData(null);
        setImageUrl(null);
        setOcrText(null);
        // Notify parent component
        if (onReceiptUpdate) {
          onReceiptUpdate(null);
        }
        setShowDeleteAlert(false); // Close the alert
      } catch (error) {
        console.error("Error deleting receipt:", error);
        // Show an error message to the user
        alert(`Failed to delete receipt: ${error.message}`);
      } finally {
        setIsDeleteDialogOpen(false);
      }
    } else {
      console.error("No receipt data or ID available for deletion");
      alert("No receipt data available to delete");
    }
  };

  const handleStoreNameEdit = () => {
    setIsEditingStoreName(true);
    setEditedStoreName(receiptData?.processed_data?.store_name || "");
  };

  const handleStoreNameSave = async () => {
    if (receiptData && receiptData.id) {
      try {
        await updateReceiptField(receiptData.id, "store_name", editedStoreName);
        setReceiptData(prevData => ({
          ...prevData,
          processed_data: {
            ...prevData.processed_data,
            store_name: editedStoreName
          }
        }));
        setIsEditingStoreName(false);
        if (onReceiptUpdate) {
          onReceiptUpdate({
            ...receiptData,
            processed_data: {
              ...receiptData.processed_data,
              store_name: editedStoreName
            }
          });
        }
      } catch (error) {
        console.error("Error updating store name:", error);
        // Optionally, show an error message to the user
      }
    }
  };

  const handleFieldEdit = async (fieldName, newValue) => {
    if (receiptData && receiptData.id) {
      try {
        let valueToSave = newValue;
        if (fieldName === 'datetime') {
          const parsedDate = parseISO(newValue);
          if (isValid(parsedDate)) {
            valueToSave = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
          } else {
            console.warn('Invalid date entered, saving as-is');
          }
        }

        await updateReceiptField(receiptData.id, fieldName, valueToSave);
        setReceiptData(prevData => ({
          ...prevData,
          processed_data: {
            ...prevData.processed_data,
            [fieldName]: valueToSave
          }
        }));
        if (onReceiptUpdate) {
          onReceiptUpdate({
            ...receiptData,
            processed_data: {
              ...receiptData.processed_data,
              [fieldName]: valueToSave
            }
          });
        }
      } catch (error) {
        console.error(`Error updating ${fieldName}:`, error);
        // Optionally, show an error message to the user
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-xl font-semibold text-gray-700">Receipt Details</h2>
        <div className="flex items-center space-x-2">
          <form onSubmit={handleLoadReceiptByUid} className="flex items-center">
            <Input
              type="text"
              placeholder="Enter Receipt UID"
              value={receiptUidInput}
              onChange={(e) => setReceiptUidInput(e.target.value)}
              className="mr-2"
            />
            <Button type="submit" variant="outline">
              <Search size={16} />
            </Button>
          </form>
          <CopyToClipboardButton
            text={receiptData?.id || ''}
            displayText={`UID: ${receiptData?.id || 'N/A'}`}
            className="w-64"
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => setShowDeleteAlert(true)}
                  variant="destructive"
                  disabled={!receiptData?.id}
                >
                  <Trash2 size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Delete this receipt</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Receipt Image</CardTitle>
            <div className="flex items-center mb-2">
              <Button onClick={handleZoomOut}><ZoomOut size={16} /></Button>
              <span className="mx-2">{Math.round(zoom * 100)}%</span>
              <Button onClick={handleZoomIn}><ZoomIn size={16} /></Button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="overflow-auto max-h-[60vh] border rounded">
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Receipt"
                  style={{
                    transform: `scale(${zoom})`,
                    transformOrigin: 'top left',
                    transition: 'transform 0.3s ease',
                    width: '100%',
                  }}
                />
              )}
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>OCR Results</CardTitle>
              <Button
                onClick={handleRegenerateOcr}
                disabled={isRegeneratingOcr}
                className={`${
                  isRegeneratingOcr ? 'bg-gray-300' : 
                  ocrRegenerationSuccess ? 'bg-green-500' : 'bg-blue-500'
                } text-white`}
              >
                {isRegeneratingOcr ? (
                  <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="mr-2 h-4 w-4" />
                )}
                {isRegeneratingOcr ? 'Regenerating...' : 
                 ocrRegenerationSuccess ? 'Regenerated!' : 'Regenerate OCR'}
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="overflow-auto max-h-[60vh] border rounded p-4">
              <pre className="whitespace-pre-wrap">{ocrText}</pre>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>Structured Data</CardTitle>
              <Button
                onClick={handleRecalculate}
                disabled={isRecalculating}
                className={`${
                  isRecalculating ? 'bg-gray-300' : 
                  recalculationSuccess ? 'bg-green-500' : 'bg-blue-500'
                } text-white`}
              >
                {isRecalculating ? (
                  <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="mr-2 h-4 w-4" />
                )}
                {isRecalculating ? 'Recalculating...' : 
                 recalculationSuccess ? 'Recalculated!' : 'Recalculate'}
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                // Reset the state that caused the error
                setReceiptData(selectedReceipt);
              }}
            >
              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-medium mb-2">Summary</h4>
                  <div className="space-y-2">
                    <EditableField
                      label="Date"
                      value={formatDate(receiptData?.processed_data?.datetime, "yyyy-MM-dd'T'HH:mm")}
                      onSave={(newValue) => handleFieldEdit('datetime', newValue)}
                      inputType="datetime-local"
                    />
                    <EditableField
                      label="Store Name"
                      value={receiptData?.processed_data?.store_name}
                      onSave={(newValue) => handleFieldEdit('store_name', newValue)}
                    />
                    <div className="flex justify-between">
                      <span>Currency:</span>
                      <span>{receiptData?.processed_data?.currency || 'N/A'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Total:</span>
                      <span>
                        {receiptData?.processed_data?.total_amount != null
                          ? `${receiptData.processed_data.total_amount.toFixed(2)}`
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="text-lg font-medium mb-2">Items</h4>
                  {receiptData && receiptData.id ? (
                    <InvoiceTable
                      renderedReceiptId={receiptData.id}
                      collectionName="allUserReceipts"
                      skeletonData={[]}
                      key={receiptData.id}
                    />
                  ) : (
                    <p>No receipt data available</p>
                  )}
                </div>
              </div>
            </ErrorBoundary>
          </CardContent>
        </Card>
      </div>

      {/* Add this new DeleteReceiptDialog component */}
      <DeleteReceiptDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDeleteReceipt}
      />

      {/* Add this new alert for delete confirmation */}
      {showDeleteAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex items-center mb-4">
              <AlertTriangle className="text-yellow-500 mr-2" size={24} />
              <h3 className="text-lg font-semibold">Confirm Deletion</h3>
            </div>
            <p className="mb-4">Are you sure you want to delete this receipt? This action cannot be undone.</p>
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setShowDeleteAlert(false)} variant="outline">Cancel</Button>
              <Button onClick={() => {
                setIsDeleteDialogOpen(true);
                setShowDeleteAlert(false);
              }} variant="destructive">Delete</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
