import React, { useState, useEffect } from "react";
import { ScrollArea, ScrollBar } from "../../@/components/scroll-area";
import ReceiptCard from "./ReceiptCard";
import { FilterSelect, getFilteredReceipts } from "./FilterSelect";

const RenderReceipts = ({ selectedDate, setRenderedReceiptId, user }) => {
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("uploadTime");

  useEffect(() => {
    getFilteredReceipts(currentFilter, selectedDate, user)
      .then(setFilteredReceipts)
      .catch((error) => console.error("Error fetching filtered receipts:", error));
  }, [currentFilter, selectedDate, user]);

  const handleReceiptClick = (receiptId) => {
    if (localStorage.getItem("userUid")) {
      setRenderedReceiptId(receiptId);
    } else {
      console.log("User is not logged in. Cannot render receipt details.");
    }
  };

  return (
    <div className="w-full mx-auto">
      <FilterSelect currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
      {filteredReceipts.length > 0 ? (
        <ScrollArea>
          <div className="flex w-max space-x-4  my-4">
            {filteredReceipts.map((receipt) => (
              <div key={receipt.id} className="cursor-pointer " onClick={() => handleReceiptClick(receipt.id)}>
                <ReceiptCard receipt={receipt} />
              </div>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      ) : (
        <p className="text-center text-gray-500 max-w-sm mx-auto">
          No data found for this date. Please check previous dates.
          Filter State: {currentFilter}
        </p>
      )}
    </div>
  );
};


export default RenderReceipts;
