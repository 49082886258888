const isLocalEnvironment = process.env.NODE_ENV === 'development';

const baseApiUrl = isLocalEnvironment
  ? "http://localhost:8080"
  : "https://grocery-tracker-66k7fisxaq-ew.a.run.app";


export default {
  baseApiUrl,
  adminEmails: ['aman.2k4@gmail.com'],
};

