import React from "react";

export default function Settings() {
  return (
    <div>
      <h1 className="text-2xl font-semibold mb-4">Settings</h1>
      {/* Add settings content here */}
    </div>
  );
}

