import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  orderBy,
  limit,
  startAfter,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase/config"; // Assuming you have a firebaseConfig.js file where you initialize your Firestore instance
import { useAuthState } from "react-firebase-hooks/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { generateConversationTitle } from "./conversationUtils";

export const fetchUserData = async (userUid) => {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("uid", "==", userUid));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userDocSnapshot = querySnapshot.docs[0];
    return userDocSnapshot.data();
  }
  return null;
};

export const copyReceiptDataToUserCollection = async (documentId) => {
  const user = auth.currentUser;
  if (user) {
    const uid = user.uid;
    const userRef = doc(db, "users", uid);
    try {
      const groceriesSnapshot = await getDoc(
        doc(db, "groceries_temp", documentId)
      );
      const receiptsSnapshot = await getDoc(
        doc(db, "allUserReceipts", documentId)
      );
      if (groceriesSnapshot.exists() && !receiptsSnapshot.exists()) {
        const receiptData = groceriesSnapshot.data();
        await setDoc(doc(db, "allUserReceipts", documentId), {
          ...receiptData,
          userRef: userRef,
        });
        console.log("Receipt data copied to user collection successfully");
      } else if (!groceriesSnapshot.exists()) {
        console.error("Document does not exist in groceries_temp");
      } else if (receiptsSnapshot.exists()) {
        console.log("Document already exists in user collection");
      }
    } catch (error) {
      console.error("Error copying receipt data:", error);
    }
  }
};

export const fetchReceiptData = async (documentId) => {
  const documentRef = doc(db, "groceries_temp", documentId);
  const docSnap = await getDoc(documentRef);
  return docSnap.exists() ? docSnap.data() : null;
};

export const fetchReceiptDetails = async (
  renderedReceiptId,
  collectionName
) => {
  try {
    if (!renderedReceiptId || !collectionName) {
      console.error("Invalid renderedReceiptId or collectionName");
      return null;
    }
    const receiptRef = doc(db, collectionName, renderedReceiptId);
    const receiptDoc = await getDoc(receiptRef);
    if (receiptDoc.exists()) {
      const receiptData = receiptDoc.data();
      return receiptData;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching receipt details:", error);
    return null;
  }
};

export const updateFirebaseDocument = (
  key,
  collectionName,
  variableName,
  variableValue
) => {
  const userDocRef = doc(db, collectionName, localStorage.getItem(key));
  updateDoc(userDocRef, {
    [variableName]: variableValue,
  })
    .then(() => {
      console.log("New variable stored in the Firebase document.");
    })
    .catch((error) => {
      console.error("Error updating the Firebase document:", error);
    });
};

export const filterReceiptsByDate = (selectedDate, filterType, user) => {
  const dateKey =
    filterType === "uploadTime"
      ? "request_timestamp"
      : "processed_data.datetime";

  if (filterType === "uploadTime") {
    return FilterDataByDate(selectedDate, dateKey, "request_timestamp", user);
  } else {
    console.log(
      "filterType is not 'uploadTime' , so filter based on shopping date"
    );
    return FilterDataByDate(
      selectedDate,
      dateKey,
      "processed_data.datetime",
      user
    );
  }
};

// Function to get the date value based on the nested key
const getDateValue = (data, key) => {
  const keys = key.split(".");
  let value = data;
  for (const k of keys) {
    value = value?.[k];
  }
  return value;
};

const FilterDataByDate = async (selectedDate, dateKey, timestampKey, user) => {
  try {
    const userRef = doc(db, "users", user.uid);
    const q = query(
      collection(db, "allUserReceipts"),
      where("userRef", "==", userRef)
    );
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs;

    return documents
      .filter((doc) => {
        const dateValue = getDateValue(doc.data(), dateKey);
        return dateValue && dateValue.slice(0, 10) === selectedDate;
      })
      .map((doc) => ({ id: doc.id, data: doc.data() }))
      .sort(
        (a, b) =>
          new Date(a.data[timestampKey]) - new Date(b.data[timestampKey])
      );
  } catch (error) {
    console.error("Error filtering documents by date:", error);
    return [];
  }
};

export const fetchTotalReceiptsCount = (
  db,
  userUid,
  setTotalReceiptsCount,
  setTotalAmountSpent
) => {
  const refreshTotalReceiptsCount = () => {
    try {
      const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
      const userRef = doc(db, "users", userUid);
      const q = query(
        allUserReceiptsCollectionRef,
        where("userRef", "==", userRef)
      );
      getDocs(q)
        .then((querySnapshot) => {
          const documents = querySnapshot.docs;
          let receiptsCount = 0;
          let amountSpent = 0;

          documents.forEach((doc) => {
            const requestData = doc.data();
            receiptsCount += 1;
            amountSpent += requestData?.processed_data?.total_amount || 0;
          });

          setTotalReceiptsCount(receiptsCount);
          setTotalAmountSpent(amountSpent);
        })
        .catch((error) => {
          console.error("Error fetching total receipts count:", error);
        });
    } catch (error) {
      console.error("Error fetching total receipts count:", error);
    }
  };

  refreshTotalReceiptsCount();

  window.addEventListener("beforeunload", refreshTotalReceiptsCount);

  return () => {
    window.removeEventListener("beforeunload", refreshTotalReceiptsCount);
  };
};

// function to calculate aggregation of expense on monthly basis

export const fetchMonthlyExpenseAggregation = async (
  db,
  userUid,
  setMonthlyExpenseAggregation
) => {
  try {
    const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
    const userRef = doc(db, "users", userUid);
    const q = query(
      allUserReceiptsCollectionRef,
      where("userRef", "==", userRef)
    );
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs;

    // aggregate the expense on monthly basis
    const monthlyExpenseAggregation = {};

    documents.forEach((doc) => {
      const requestData = doc.data();
      const date = requestData?.processed_data?.datetime ?? "Unknown";
      const month = date.slice(0, 7);
      monthlyExpenseAggregation[month] =
        (monthlyExpenseAggregation[month] || 0) +
        (requestData?.processed_data?.total_amount || 0);
    });

    // print the monthly expense aggregation
    setMonthlyExpenseAggregation(monthlyExpenseAggregation);
  } catch (error) {
    console.error("Error fetching monthly expense aggregation:", error);
  }
};

export const fetchTextFileContent = async (filePath) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, filePath);
    const url = await getDownloadURL(fileRef);
    const response = await fetch(url);
    const text = await response.text();
    return text;
  } catch (error) {
    console.error("Error fetching text file content:", error);
    return null;
  }
};

export const fetchAllUsers = async () => {
  try {
    const usersCollectionRef = collection(db, "users");
    const querySnapshot = await getDocs(usersCollectionRef);
    return querySnapshot.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

export const fetchUserReceipts = async (userUid) => {
  try {
    const userRef = doc(db, "users", userUid);
    const q = query(
      collection(db, "allUserReceipts"),
      where("userRef", "==", userRef)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching user receipts:", error);
    return [];
  }
};

export const fetchUserDetails = async (userUid) => {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("uid", "==", userUid));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userDocSnapshot = querySnapshot.docs[0];
    const userData = userDocSnapshot.data();

    // Convert created_time and upload_date fields to ISO strings
    if (userData.created_time) {
      userData.created_time = new Date(
        userData.created_time.seconds * 1000
      ).toISOString();
    }

    if (userData.user_uploaded_receipts) {
      userData.user_uploaded_receipts = userData.user_uploaded_receipts.map(
        (receipt) => ({
          ...receipt,
          upload_date: new Date(
            receipt.upload_date.seconds * 1000
          ).toISOString(), // Convert to ISO string
        })
      );
    }

    return userData;
  }
  return null;
};

// Function to upload audio to Firebase Storage and get the file path
export const uploadAudioToStorage = async (audioFile, userUid, docUID, role, index) => {
  const storage = getStorage();
  const filePath = `audio/${userUid}/${docUID}_${role}_${index}`;
  const storageRef = ref(storage, filePath);
  await uploadBytes(storageRef, audioFile);
  return `gs://mandi-3e35b.appspot.com/${filePath}`; // Return the file path instead of the URL
};

// Function to save / update conversation in Firestore
export const saveConversation = async (conversationData, conversationId) => {
  const user = auth.currentUser;
  if (!user) return;

  const userUid = user.uid;
  console.log("conversationId", conversationId);

  const timestamp = new Date().toISOString(); // Add timestamp

  if (conversationId) {
    const conversationRef = doc(db, "cookwithme_talks", conversationId);

    // Fetch the existing conversation
    const conversationDoc = await getDoc(conversationRef);
    if (conversationDoc.exists()) {
      const existingConversation = conversationDoc.data().conversation || [];

      if (existingConversation.length === 6) {
        const title = await generateConversationTitle(existingConversation);
        await updateDoc(conversationRef, {
          title,
        });
      }

      // Append the new conversation data to the existing conversation
      const updatedConversation = [
        ...existingConversation,
        ...conversationData,
      ];

      // Update the document with the combined conversation data and timestamp
      await updateDoc(conversationRef, {
        conversation: updatedConversation,
        timestamp, // Update timestamp
      });
    } else {
      console.error("Conversation document does not exist");
    }
  } else {
    const conversationRef = doc(collection(db, "cookwithme_talks"));

    const newConversationData = {
      user_uid: userUid,
      conversation: conversationData,
      timestamp, // Add timestamp
    };

    await setDoc(conversationRef, newConversationData);

    // Update user's document with the conversation UID
    const userRef = doc(db, "users", userUid);
    await updateDoc(userRef, {
      cookwithme_talks: arrayUnion(conversationRef.id),
    });
    return conversationRef.id;
  }
};

export const saveUsageToFirestore = async (userUid, usage) => {
  if (!userUid) return;
  try {
    const userRef = doc(db, "users", userUid);
    const userDoc = await getDoc(userRef);

    let totalTokens = usage.total_tokens;
    let totalInputTokens = usage.input_tokens;
    let totalOutputTokens = usage.output_tokens;

    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (userData.tokenUsage) {
        // Sum the current tokens with the previous totals
        totalTokens += userData.tokenUsage.reduce(
          (acc, entry) => acc + entry.total_tokens,
          0
        );
        totalInputTokens += userData.tokenUsage.reduce(
          (acc, entry) => acc + entry.input_tokens,
          0
        );
        totalOutputTokens += userData.tokenUsage.reduce(
          (acc, entry) => acc + entry.output_tokens,
          0
        );
      }
    }

    await updateDoc(userRef, {
      tokenUsage: arrayUnion({
        timestamp: new Date().toISOString(),
        total_tokens: usage.total_tokens,
        input_tokens: usage.input_tokens,
        output_tokens: usage.output_tokens,
      }),
      totalTokensUsed: totalTokens,
      totalInputTokensUsed: totalInputTokens,
      totalOutputTokensUsed: totalOutputTokens,
    });

    console.log("Token usage saved successfully.");
  } catch (error) {
    console.error("Error saving token usage:", error);
  }
};

export const fetchLastConversations = async () => {
  const user = auth.currentUser;
  
  if (!user) return [];

  const conversationsRef = collection(db, "cookwithme_talks");
  const q = query(
    conversationsRef,
    where("user_uid", "==", user.uid),
    orderBy("timestamp", "desc"),
    // limit(5)
  );
  const querySnapshot = await getDocs(q);
 
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};


export const getFirebaseUrl = async (URL) => {
  if (!URL) return null;
  if (!URL.startsWith('gs://')) {
    return null;
  }

  const storage = getStorage();
  const storageRef = ref(storage, URL);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error getting Firebase URL:", error);
    return null;
  }
};

export const fetchReceiptByUid = async (receiptUid) => {
  try {
    const receiptRef = doc(db, "allUserReceipts", receiptUid);
    const receiptDoc = await getDoc(receiptRef);
    
    if (receiptDoc.exists()) {
      return { id: receiptDoc.id, ...receiptDoc.data() };
    } else {
      console.log("No receipt found with the given UID");
      return null;
    }
  } catch (error) {
    console.error("Error fetching receipt by UID:", error);
    throw error;
  }
};

export const deleteReceiptByUid = async (receiptUid) => {
  try {
    const receiptRef = doc(db, "allUserReceipts", receiptUid);
    await deleteDoc(receiptRef);
    console.log("Receipt deleted successfully");
    return true;
  } catch (error) {
    console.error("Error deleting receipt:", error);
    throw error;
  }
};

export const updateReceiptField = async (receiptId, field, value) => {
  try {
    const receiptRef = doc(db, "allUserReceipts", receiptId);
    await updateDoc(receiptRef, {
      [`processed_data.${field}`]: value
    });
    console.log(`Receipt ${field} updated successfully`);
    return true;
  } catch (error) {
    console.error(`Error updating receipt ${field}:`, error);
    throw error;

  }
};
