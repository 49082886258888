import React from "react";
import { Button } from "../../@/components/button";
import { Copy } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../@/components/tooltip";

export default function CopyToClipboardButton({ text, displayText, className }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard');
    });
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={copyToClipboard}
            className={`text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 flex items-center justify-between px-3 py-2 ${className}`}
          >
            <span className="truncate mr-2">{displayText}</span>
            <Copy size={16} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Click to copy full text</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

