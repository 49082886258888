import { format, parseISO, isValid } from "date-fns";

export const formatDate = (dateString, formatString = "MMM d, yyyy h:mm a") => {
  if (!dateString) return "";
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? format(parsedDate, formatString) : "";
};

export const formatedDatetime = (date) => {
  try {
    return format(new Date(date), "EEEE, do MMM  HH:mm").replace(/(\d)(st|nd|rd|th)/, '$1');
  } catch (error) {
    console.error(error);
    return "NA";
  }
};
