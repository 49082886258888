import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../@/components/card";
import { fetchAllUsers, fetchUserReceipts } from "../../utils/firestoreUtils";
import { Users, FileText, Clock, Copy, ChevronUp, ChevronDown } from "lucide-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../@/components/table";
import { Button } from "../../@/components/button";
import CopyToClipboardButton from "../common/CopyToClipboardButton";


export default function AdminDashboard() {
  const [userStats, setUserStats] = useState({
    totalUsers: 0,
    usersWithReceipts: 0,
    lastLoginDate: null,
    totalReceipts: 0,
  });
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        const users = await fetchAllUsers();
        let totalReceipts = 0;
        let usersWithReceipts = 0;
        let userDetailsArray = [];

        for (const user of users) {
          const userReceipts = await fetchUserReceipts(user.uid);
          const receiptCount = userReceipts.length;
          if (receiptCount > 0) {
            usersWithReceipts++;
            totalReceipts += receiptCount;
          }
          userDetailsArray.push({
            uid: user.uid,
            email: user.email,
            receiptCount: receiptCount,
          });
        }

        const lastLoginDate = users.reduce((latest, user) => {
          return user.lastLogin && (!latest || user.lastLogin > latest) ? user.lastLogin : latest;
        }, null);

        setUserStats({
          totalUsers: users.length,
          usersWithReceipts,
          lastLoginDate,
          totalReceipts,
        });
        setUserDetails(userDetailsArray);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
      setIsLoading(false);
    };

    fetchStats();
  }, []);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : 'N/A';
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...userDetails].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setUserDetails(sortedData);
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />;
    }
    return null;
  };

  return (
    <div className="space-y-8">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Users</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? "Loading..." : userStats.totalUsers}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Users with Receipts</CardTitle>
            <FileText className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? "Loading..." : userStats.usersWithReceipts}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Last Login</CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? "Loading..." : formatDate(userStats.lastLoginDate)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Receipts</CardTitle>
            <FileText className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? "Loading..." : userStats.totalReceipts}</div>
          </CardContent>
        </Card>
      </div>
      
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead onClick={() => sortData('uid')} className="cursor-pointer">
                User UID {getSortIcon('uid')}
              </TableHead>
              <TableHead onClick={() => sortData('email')} className="cursor-pointer">
                Email {getSortIcon('email')}
              </TableHead>
              <TableHead onClick={() => sortData('receiptCount')} className="cursor-pointer">
                Receipt Count {getSortIcon('receiptCount')}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={3} className="text-center">Loading...</TableCell>
              </TableRow>
            ) : (
              userDetails.map((user) => (
                <TableRow key={user.uid}>
                  <TableCell>
                    <CopyToClipboardButton
                      text={user.uid}
                      displayText={user.uid}
                      className="w-full"
                    />
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.receiptCount}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
