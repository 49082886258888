import {  doc, getDoc, setDoc } from 'firebase/firestore';
import {  db } from "./config";


export const ensureUserDocument = async (user) => {
  console.log("Starting ensureUserDocument for user:", user.uid);
  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    console.log("User document doesn't exist, creating new one");
    try {
      await setDoc(userRef, {
        created_time: new Date().toISOString(),
        display_name: user.displayName || null,
        email: user.email,
        uid: user.uid
      });
      console.log('New user document created in Firestore');
    } catch (error) {
      console.error('Error creating user document:', error);
      throw error; // Re-throw the error so it can be caught in authUtils.js
    }
  } else {
    console.log('User document already exists in Firestore');
  }
};