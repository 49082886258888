// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCaHg7Z8DVqwl5jYYMUCFygurcZ-Z8cHn4",
  // authDomain: "mandi-3e35b.firebaseapp.com",
  authDomain: "mandinow.com",
  projectId: "mandi-3e35b",
  storageBucket: "mandi-3e35b.appspot.com",
  messagingSenderId: "182206783951",
  appId: "1:182206783951:web:fc2bb8289f5bcf415732ba",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase App Check
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfDee4pAAAAAMwH_VHCDrlD_z82dKwk0RgZRL0h"),
  isTokenAutoRefreshEnabled: true,
});

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

// add code here which takes in image_url and if it is having expired token then regereate the image url with the new token so that it can be viewed in the frontment

// the function takes in image_url and returns the new image url with the new token
//

const getFirebaseImageUrl = async (imageUri) => {
  if (!imageUri.startsWith('gs://')) {
    throw new Error('Invalid image URI format. Expected gs:// format.');
  }

  const storage = getStorage();
  const storageRef = ref(storage, imageUri);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error getting Firebase image URL:", error);
    throw error;
  }
};

async function fetchTextData(filePath) {
  if (!filePath.startsWith('gs://')) {
    throw new Error('Invalid file URI format. Expected gs:// format.');
  }

  const storage = getStorage();
  const fileRef = ref(storage, filePath);

  try {
    const url = await getDownloadURL(fileRef);
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const textData = await response.text();
    return textData;
  } catch (error) {
    console.error("There was a problem fetching the text file:", error);
    throw error;
  }
}

export { 
  auth, 
  provider, 
  db, 
  storage, 
  getFirebaseImageUrl, 
  fetchTextData 
};
