import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FileClock, Home, ShoppingCart } from "lucide-react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../@/components/tooltip";
import { AudioLines } from "lucide-react";
import { useSelector } from "react-redux";
// Reusable Link Component
const SidebarLink = ({ to, icon: Icon, label, isActive }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={to}
          className={`flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8 ${isActive ? "bg-amber-200 text-accent-foreground" : "text-muted-foreground hover:bg-zinc-100"}`}
        >
          <Icon className="h-5 w-5" />
          <span className="sr-only">{label}</span>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{label}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default function DashboardMobileNavbar() {
  const location = useLocation(); // Get the current location
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      <aside className="fixed bottom-0  left-0 z-10 flex sm:hidden w-full flex-col border-r bg-white ">
        <nav className="flex justify-around py-2  items-center gap-4 px-2 sm:py-4">
          <SidebarLink
            to="/dashboard"
            icon={Home}
            label="Dashboard"
            isActive={location.pathname === "/dashboard"}
          />
          <SidebarLink
            to="/dashboard/past-receipts"
            icon={FileClock}
            label="Past Receipts"
            isActive={location.pathname === "/dashboard/past-receipts"}
          />
          <SidebarLink
            to="/dashboard/grocery-list"
            icon={ShoppingCart}
            label="Grocery List"
            isActive={location.pathname === "/dashboard/grocery-list"}
          />
          {user?.betaTester && (
            <SidebarLink
              to="/dashboard/cook-with-me"
              icon={AudioLines}
              label="Cook With Me"
              isActive={location.pathname === "/dashboard/cook-with-me"}
            />
          )}
        </nav>
      </aside>
    </div>
  );
}
