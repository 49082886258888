import React, { useEffect, useState, useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { TrendingUp, ChevronDown } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../@/components/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../@/components/chart";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../@/components/dropdown-menu";
import { Button } from "../../@/components/button";

const ExpenseChart = () => {
  const [originalData, setOriginalData] = useState([]);
  const [processedDataForPlotting, setProcessedDataForPlotting] = useState([]);
  const [granularity, setGranularity] = useState("day");
  const userUid = localStorage.getItem("userUid");

  useEffect(() => {
    const fetchDataForPlotting = async () => {
      try {
        const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
        const userRef = doc(db, "users", userUid);
        const q = query(
          allUserReceiptsCollectionRef,
          where("userRef", "==", userRef)
        );
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs;

        const newDataForPlotting = documents
          .map((doc) => {
            const requestData = doc.data();
            return {
              date: requestData?.processed_data?.datetime ?? "",
              amount: parseFloat(
                requestData?.processed_data?.total_amount ?? "0"
              ),
              supermarket: requestData?.processed_data?.store_name ?? "Unknown",
            };
          })
          .filter(item => item.date !== "");

        // Sort the data by date
        newDataForPlotting.sort((a, b) => new Date(a.date) - new Date(b.date));

        setOriginalData(newDataForPlotting);
        const groupedData = groupDataByGranularity(newDataForPlotting, granularity);
        setProcessedDataForPlotting(Object.values(groupedData));
      } catch (error) {
        console.error("Error fetching data for plotting:", error);
      }
    };

    fetchDataForPlotting();
  }, [userUid]);

  const groupDataByGranularity = (data, granularity) => {
    return data.reduce((acc, curr) => {
      let dateKey;
      if (granularity === "day") {
        dateKey = curr.date.split("T")[0];
      } else if (granularity === "month") {
        dateKey = curr.date.substring(0, 7); // YYYY-MM
      }
      
      if (!acc[dateKey]) {
        acc[dateKey] = { date: dateKey, amount: 0 };
      }
      acc[dateKey].amount += curr.amount;
      return acc;
    }, {});
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    if (granularity === "day") {
      return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
    } else if (granularity === "month") {
      return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
    }
  };

  const chartConfig = useMemo(() => ({
    amount: {
      label: "Total Amount",
      color: "hsl(var(--chart-1))",
    },
  }), []);

  const handleGranularityChange = (newGranularity) => {
    setGranularity(newGranularity);
    const groupedData = groupDataByGranularity(originalData, newGranularity);
    setProcessedDataForPlotting(Object.values(groupedData));
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div>
          <CardTitle className="text-base font-normal">Expense Chart</CardTitle>
          <CardDescription>Since joining</CardDescription>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto h-8 lg:flex">
              {granularity === 'day' ? 'Daily' : 'Monthly'}
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => handleGranularityChange('day')}>
              Daily
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleGranularityChange('month')}>
              Monthly
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent>
        {processedDataForPlotting && processedDataForPlotting.length > 0 ? (
          <ChartContainer
            config={chartConfig}
            className="h-[300px] w-full"
          >
            <BarChart data={processedDataForPlotting}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={formatXAxis}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => `€${value}`}
                width={80}
                tickCount={5}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent />}
              />
              <Bar dataKey="amount" fill="var(--color-amount)" radius={4} />
            </BarChart>
          </ChartContainer>
        ) : (
          <div>No data available</div>
        )}
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          {/* You might want to calculate this percentage based on the data */}
          Trending up by X% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total expenses for the last 4 weeks
        </div>
      </CardFooter>
    </Card>
  );
};

export default ExpenseChart;
