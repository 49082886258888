import React, { useState, useEffect } from 'react';
import { Input } from "../../@/components/input";
import { Button } from "../../@/components/button";
import { format, parseISO, isValid } from 'date-fns';

export default function EditableField({ label, value, onSave, inputType = 'text' }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleSave = () => {
    if (inputType === 'datetime-local') {
      // Convert the datetime-local value to ISO string format
      const date = new Date(editedValue);
      onSave(isValid(date) ? date.toISOString() : '');
    } else {
      onSave(editedValue);
    }
    setIsEditing(false);
  };

  const formatDateTimeLocal = (value) => {
    if (!value) return '';
    const date = parseISO(value);
    return isValid(date) ? format(date, "yyyy-MM-dd'T'HH:mm") : '';
  };

  return (
    <div className="flex justify-between items-center">
      <span>{label}:</span>
      {isEditing ? (
        <div className="flex items-center">
          <Input
            type={inputType}
            value={inputType === 'datetime-local' ? formatDateTimeLocal(editedValue) : editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            className="mr-2"
          />
          <Button onClick={handleSave} variant="outline" size="sm">
            Save
          </Button>
        </div>
      ) : (
        <span
          onDoubleClick={() => setIsEditing(true)}
          className="cursor-pointer hover:underline"
        >
          {value || 'N/A'}
        </span>
      )}
    </div>
  );
}
