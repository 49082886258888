import { useEffect, useState, useMemo } from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { TrendingUp } from "lucide-react";
import { ChevronDown } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../@/components/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../@/components/chart";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../@/components/dropdown-menu";
import { Button } from "../../@/components/button";

const GroceryCategoryChart = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [totalSpent, setTotalSpent] = useState(0);
  const userUid = localStorage.getItem("userUid");
  const [topCategoriesCount, setTopCategoriesCount] = useState(5);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
        const userRef = doc(db, "users", userUid);
        const q = query(
          allUserReceiptsCollectionRef,
          where("userRef", "==", userRef)
        );
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs;

        const categoryTotals = documents.reduce((acc, doc) => {
          const requestData = doc.data();
          const items = requestData?.processed_data?.items || [];
          items.forEach(item => {
            const category = item.category || "Uncategorized";
            const amount = parseFloat(item.total_price) || 0;
            acc[category] = (acc[category] || 0) + amount;
          });
          return acc;
        }, {});

        const sortedCategories = Object.entries(categoryTotals)
          .sort((a, b) => b[1] - a[1]);

        const topCategories = sortedCategories.slice(0, topCategoriesCount);
        const otherCategories = sortedCategories.slice(topCategoriesCount);

        const newCategoryData = topCategories.map(([name, value]) => ({
          name,
          value: parseFloat(value.toFixed(2)),
        }));

        if (otherCategories.length > 0) {
          const otherTotal = otherCategories.reduce((sum, [_, value]) => sum + value, 0);
          newCategoryData.push({
            name: "Other",
            value: parseFloat(otherTotal.toFixed(2)),
          });
        }

        // Calculate total spent
        const total = newCategoryData.reduce((sum, category) => sum + category.value, 0);
        setTotalSpent(total);
        setCategoryData(newCategoryData);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [userUid, topCategoriesCount]);

  const COLORS = ['hsl(var(--chart-1))', 'hsl(var(--chart-2))', 'hsl(var(--chart-3))', 'hsl(var(--chart-4))', 'hsl(var(--chart-5))'];

  const chartConfig = useMemo(() => {
    return categoryData.reduce((config, category, index) => {
      config[category.name] = {
        label: category.name,
        color: COLORS[index % COLORS.length],
      };
      return config;
    }, {
      value: { label: "Amount" }
    });
  }, [categoryData]);

  const handleTopCategoriesChange = (newCount) => {
    setTopCategoriesCount(newCount);
  };

  return (
    <Card className="flex flex-col">
      <CardHeader className="flex flex-row items-center justify-between pb-0">
        <div>
          <CardTitle>Top Grocery Categories</CardTitle>
          <CardDescription>Expenses by top {topCategoriesCount} categories</CardDescription>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto h-8 lg:flex">
              Top {topCategoriesCount}
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {[3, 5, 7, 10].map((count) => (
              <DropdownMenuItem key={count} onClick={() => handleTopCategoriesChange(count)}>
                Top {count}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent />}
            />
            <Pie
              data={categoryData}
              dataKey="value"
              nameKey="name"
              innerRadius={80}
              outerRadius={100}
              strokeWidth={5}
            >
              {categoryData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          €{totalSpent.toFixed(2)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Spent
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing top {topCategoriesCount} categories by spending
        </div>
      </CardFooter>
    </Card>
  );
};

export default GroceryCategoryChart;
