import apiClient from '../apiClient';
import endpoints from '../endpoints';


export const callLlmStructured = async (key, collectionName = "groceries_temp") => {
  try {
    const response = await apiClient.post(endpoints.callLlmStructured, null, {
      params: {
        key,
        max_tokens: "10000",
        // system_prompt_path: "gs://mandi-3e35b.appspot.com/global_prompts/prompt_pair_2/system_message_2.txt",
        // user_prompt_path: "gs://mandi-3e35b.appspot.com/global_prompts/prompt_pair_2/user_message_2.txt",
        model_name: "gpt-4o",
        collection_name: collectionName,
      },
    });
    console.log(`Call LLM Structured API call successful`);
    return response;
  } catch (error) {
    console.error(`Error in Call LLM Structured API call:`, error);
    throw error;
  }
};
