import { Button } from "../../../@/components/button.js";
import { Badge } from "../../../@/components/badge.js";
import { Trash2 } from "lucide-react";

const GroceryListDisplay = ({ filteredLists, setSelectedList, deleteList }) => {
  return (
    <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6`}>
      {filteredLists.map((list) => (
        <div
          key={list?.id}
          className={`shadow rounded-lg overflow-hidden h-fit ${list?.backgroundColor} `}
        >
          <div
            className={`px-4 py-2 font-semibold text-gray-700 flex justify-between items-center`}
          >
            <span>{list?.name}</span>
            <Badge variant="secondary">{list?.items?.length} items</Badge>
          </div>
          <div className="p-4 h-ful bg-white bg-opacity-60">
            <p className="text-sm text-gray-500 mb-2">
              Created:
              {list?.date_created instanceof Date
                ? list.date_created.toLocaleDateString()
                : list?.date_created &&
                    typeof list.date_created.toDate === "function"
                  ? list.date_created.toDate().toLocaleDateString()
                  : "Unknown date"}
            </p>
            <ul className="space-y-1 mb-4">
              {list?.items?.slice(0, 3).map((item) => (
                <li key={item.item_id} className="text-sm">
                  {item?.item_name} (x{item?.quantity})
                </li>
              ))}
              {list?.items?.length > 3 && (
                <li className="text-sm text-gray-500">
                  ...and {list?.items?.length - 3} more
                </li>
              )}
            </ul>
            {list?.items?.length === 0 && (
              <div className="text-sm mb-4">
                No items added yet
              </div>
            )}
            <div className="flex mt-auto items-end justify-between">
              <Button variant="outline" onClick={() => setSelectedList(list)}>
                View Details
              </Button>
              <Button
                variant="destructive"
                onClick={() => deleteList(list?.id)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroceryListDisplay;
